"use client";

import {
  PropsWithChildren,
  createContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import type { DeepReadonly } from "ts-essentials";

export interface ErrorModalVariables {
  readonly errorModalData: any;
  readonly triggerErrorModal?: (errorData: any) => void;
  readonly showErrorModal: boolean;
}

export const ErrorModalContext = createContext<ErrorModalVariables>({
  errorModalData: undefined,
  showErrorModal: false,
  triggerErrorModal: undefined,
});

export const ErrorModalContextProvider = ({
  children,
}: DeepReadonly<PropsWithChildren<unknown>>): JSX.Element => {
  const [errorModalData, setErrorModalData] = useState(null);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const triggerErrorModal = (errorData: any) => {
    setErrorModalData(errorData);
    setShowErrorModal(true);
  };

  useEffect(() => {
    if (!showErrorModal) return;
    setTimeout(() => {
      setShowErrorModal(false);
      location.reload();
    }, (errorModalData as any).retryAfter * 1000 ?? 30000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showErrorModal]);

  const errorModalProviderParameters = useMemo(
    () => ({
      errorModalData,
      showErrorModal,
      triggerErrorModal,
    }),
    [errorModalData, showErrorModal],
  );

  return (
    <ErrorModalContext.Provider value={errorModalProviderParameters}>
      {children}
    </ErrorModalContext.Provider>
  );
};
