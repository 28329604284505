import { CommonAppContext } from "@bay1/data";
import type { CardProduct } from "@bay1/sdk/generated/graphql";
import { IntegrationEnvironment, UserRole } from "@bay1/sdk/generated/graphql";
import { ConditionalWrapper, TestOnly } from "@bay1/ui";
import { OpsOnly } from "@bay1/ui/components/OpsOnly";
import { OpsRoles, checkUserAccess } from "@bay1/ui/helpers";
import { isProductionDeployment } from "@bay1/ui/urlHelper";
import classNames from "classnames";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useCallback, useContext, useEffect, useState } from "react";

interface SidebarLinkNavItem {
  text: string;
  href?: string;
  liveOnly?: boolean;
  isActiveLink?: boolean;
  userHasAccess: boolean;
  testOnly: boolean;
  opsRoles?: string[];
  nonProdOnly?: boolean;
}

interface CardProductSidebarLinkNavItem extends SidebarLinkNavItem {
  icon: React.ReactElement<SVGElement>;
  iconActive: React.ReactElement<SVGElement>;
}

export const SidebarLink = (
  props: Readonly<CardProductSidebarLinkNavItem>,
): JSX.Element => {
  const {
    text,
    href,
    liveOnly,
    isActiveLink = false,
    userHasAccess = false,
    icon,
    iconActive,
    testOnly,
    opsRoles,
    nonProdOnly = false,
  } = props;

  const { activeOrganization: organization } = useContext(CommonAppContext);

  if (nonProdOnly && isProductionDeployment) {
    return <></>;
  }

  const pageHref = userHasAccess ? href : undefined;
  const disabled = !(pageHref ?? "");
  const isSmall = "smallIcon" in props;

  const anchor = (
    <a
      className={classNames(
        "rounded-rounded group relative flex items-center px-2 py-1 text-sm",
        {
          "text-black": !disabled,
          "cursor-not-allowed text-gray-400 ": disabled,
          "bg-ash rounded-full opacity-100": isActiveLink,
          "hover:bg-ash": !isActiveLink && !disabled,
          "py-1.5 text-xs": isSmall,
        },
      )}
      data-testid="sidebar::anchor"
      key={text}
    >
      <div
        className={classNames({
          "absolute mr-3 h-5 w-5 text-black": !isSmall,
          "opacity-30": !isActiveLink && disabled,
          "opacity-0": isActiveLink,
        })}
      >
        {icon}
      </div>
      <div
        className={classNames({
          "mr-3 h-5 w-5 text-black": !isSmall,
          "opacity-0": !isActiveLink,
          "opacity-100": isActiveLink,
        })}
      >
        {iconActive}
      </div>

      <span className="truncate">{text}</span>

      {(liveOnly ?? false) && (
        <span className="bg-ash ml-2 inline-flex items-center rounded px-2 py-0.5 text-xs font-medium text-black">
          Live Only
        </span>
      )}
      {opsRoles !== undefined && testOnly && (
        <TestOnly>
          <img alt="" className="ml-2" src="/img/test-icon.svg" />
        </TestOnly>
      )}
      {opsRoles !== undefined && (
        <OpsOnly roles={opsRoles}>
          <img alt="" className="ml-2" src="/img/ops-icon-inverse.svg" />
        </OpsOnly>
      )}
    </a>
  );

  if (opsRoles !== undefined && testOnly) {
    return organization?.profile.environment === IntegrationEnvironment.TEST ? (
      <TestOnly displayForOps={true}>
        {pageHref === undefined ? (
          anchor
        ) : (
          <Link href={pageHref} key={text} legacyBehavior>
            {anchor}
          </Link>
        )}
      </TestOnly>
    ) : (
      <OpsOnly roles={opsRoles}>
        {pageHref === undefined ? (
          anchor
        ) : (
          <Link href={pageHref} key={text} legacyBehavior>
            {anchor}
          </Link>
        )}
      </OpsOnly>
    );
  }

  if (opsRoles !== undefined) {
    return (
      <OpsOnly roles={opsRoles}>
        {pageHref === undefined ? (
          anchor
        ) : (
          <Link href={pageHref} key={text} legacyBehavior>
            {anchor}
          </Link>
        )}
      </OpsOnly>
    );
  } else {
    return (
      <ConditionalWrapper condition={testOnly} wrapper={TestOnly}>
        {pageHref === undefined ? (
          anchor
        ) : (
          <Link href={pageHref} key={text} legacyBehavior>
            {anchor}
          </Link>
        )}
      </ConditionalWrapper>
    );
  }
};

export const SidebarCardProduct = ({
  cardProduct,
  organizationHasSingleProduct,
}: Readonly<{
  cardProduct: CardProduct;
  organizationHasSingleProduct: boolean;
}>): JSX.Element => {
  const router = useRouter();
  const { pathname } = useRouter();
  const { user } = useContext(CommonAppContext);
  const { cardProductId: cardProductIds, id: organizationIds } = router.query;
  const [cardProductId] = Array(cardProductIds).flat();
  const [organizationId] = Array(organizationIds).flat();
  const [isExpanded, setIsExpanded] = useState(false);

  const handleSetIsExpanded = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded]);

  useEffect(() => {
    if (cardProductId !== cardProduct.id) {
      setIsExpanded(false);
    }
  }, [cardProduct.id, cardProductId]);

  const sidebarCardProductLinks: CardProductSidebarLinkNavItem[] = [
    {
      text: "Applications",
      href: `/organizations/${organizationId}/card-products/${cardProduct.id}/applications`,
      isActiveLink:
        pathname ===
          "/organizations/[id]/card-products/[cardProductId]/applications" &&
        cardProduct.id === cardProductId,
      userHasAccess: checkUserAccess(
        [
          UserRole.ADMIN,
          UserRole.DEVELOPER,
          UserRole.FINANCE,
          UserRole.SUPPORT,
        ],
        user?.roles,
      ),

      icon: <img alt="" src="/img/application-icon-inactive.svg" />,
      iconActive: <img alt="" src="/img/application-icon.svg" />,
      testOnly: false,
    },
    {
      text: "Transactions",
      href: `/organizations/${organizationId}/card-products/${cardProduct.id}/transaction-events`,
      isActiveLink:
        pathname ===
          "/organizations/[id]/card-products/[cardProductId]/transaction-events" &&
        cardProduct.id === cardProductId,
      userHasAccess: checkUserAccess(
        [
          UserRole.ADMIN,
          UserRole.DEVELOPER,
          UserRole.FINANCE,
          UserRole.SUPPORT,
        ],
        user?.roles,
      ),

      icon: <img alt="" src="/img/auth-icon.svg" />,
      iconActive: <img alt="" src="/img/transaction-icon.svg" />,
      testOnly: false,
    },
    {
      text: "Disputes",
      href: `/organizations/${organizationId}/card-products/${cardProduct.id}/disputes`,
      isActiveLink:
        pathname ===
          "/organizations/[id]/card-products/[cardProductId]/disputes" &&
        cardProduct.id === cardProductId,
      userHasAccess: checkUserAccess(
        [
          UserRole.ADMIN,
          UserRole.DEVELOPER,
          UserRole.FINANCE,
          UserRole.SUPPORT,
        ],
        user?.roles,
      ),
      icon: <img alt="" src="/img/disputes-icon.svg" />,
      iconActive: <img alt="" src="/img/disputes-icon-active.svg" />,
      testOnly: true,
      opsRoles: [
        OpsRoles.DISPUTES_MGMT_MANAGER,
        OpsRoles.DISPUTES_MGMT_SUPPORT,
      ],
    },
    {
      text: "Cards",
      href: `/organizations/${organizationId}/card-products/${cardProduct.id}/payment-cards`,
      isActiveLink:
        pathname ===
          "/organizations/[id]/card-products/[cardProductId]/payment-cards" &&
        cardProduct.id === cardProductId,
      userHasAccess: checkUserAccess(
        [
          UserRole.ADMIN,
          UserRole.DEVELOPER,
          UserRole.FINANCE,
          UserRole.SUPPORT,
        ],
        user?.roles,
      ),

      icon: <img alt="" src="/img/card-icon.svg" />,
      iconActive: <img alt="" src="/img/card-icon-active.svg" />,
      testOnly: false,
    },
    {
      text: "Transfers",
      href: `/organizations/${organizationId}/card-products/${cardProduct.id}/transfers?transferType=Originated`,
      isActiveLink:
        pathname ===
          "/organizations/[id]/card-products/[cardProductId]/transfers" &&
        cardProduct.id === cardProductId,
      userHasAccess: checkUserAccess(
        [UserRole.ADMIN, UserRole.SUPPORT],
        user?.roles,
      ),

      icon: <img alt="" src="/img/ledger.svg" />,
      iconActive: <img alt="" src="/img/ledger-icon-active.svg" />,
      testOnly: false,
    },
    {
      text: "Card Orders",
      href: `/organizations/${organizationId}/card-products/${cardProduct.id}/card-orders`,
      isActiveLink:
        pathname ===
          "/organizations/[id]/card-products/[cardProductId]/card-orders" &&
        cardProduct.id === cardProductId,
      userHasAccess: checkUserAccess(
        [
          UserRole.ADMIN,
          UserRole.DEVELOPER,
          UserRole.FINANCE,
          UserRole.SUPPORT,
        ],
        user?.roles,
      ),

      icon: <img alt="" src="/img/card-orders.svg" />,
      iconActive: <img alt="" src="/img/card-orders-active.svg" />,
      testOnly: false,
    },
    {
      text: "Balance",
      href: `/organizations/${organizationId}/card-products/${cardProduct.id}/balance`,
      isActiveLink:
        pathname ===
          "/organizations/[id]/card-products/[cardProductId]/balance" &&
        cardProduct.id === cardProductId,
      userHasAccess: checkUserAccess(
        [UserRole.ADMIN, UserRole.FINANCE],
        user?.roles,
      ),

      icon: <img alt="" src="/img/balance.svg" />,
      iconActive: <img alt="" src="/img/balance-active.svg" />,
      testOnly: false,
    },
    {
      text: "Product Settings",
      href: `/organizations/${organizationId}/card-products/${cardProduct.id}/settings`,
      isActiveLink:
        pathname ===
          "/organizations/[id]/card-products/[cardProductId]/settings" &&
        cardProduct.id === cardProductId,
      userHasAccess: checkUserAccess(
        [UserRole.ADMIN, UserRole.FINANCE, UserRole.DEVELOPER],
        user?.roles,
      ),

      icon: <img alt="" src="/img/card-settings.svg" />,
      iconActive: <img alt="" src="/img/card-settings-active.svg" />,
      testOnly: false,
    },
  ];

  if (organizationHasSingleProduct) {
    return (
      <div className="px-0 md:px-2">
        <div className="flex grow flex-col">
          <nav aria-label="Sidebar" className="flex-1">
            <div className="space-y-0.5" role="group">
              {sidebarCardProductLinks.map((link) => (
                <SidebarLink key={link.text} {...link} />
              ))}
            </div>
          </nav>
        </div>
      </div>
    );
  }

  return (
    <div className="px-0 md:px-2">
      <div
        className="rounded-rounded hover:bg-ash mb-0.5 flex cursor-pointer items-center truncate px-2 py-1"
        data-testid="sidebar::cardProduct"
        onClick={handleSetIsExpanded}
      >
        {isExpanded || cardProductId === cardProduct.id ? (
          <span className="shrink-0 pl-1.5">
            <img alt="" src="/img/nav-dropdown.svg" />
          </span>
        ) : (
          <span className="shrink-0 rotate-180 pr-1.5 opacity-20">
            <img alt="" src="/img/nav-dropdown.svg" />
          </span>
        )}
        <span
          className={classNames("group ml-0.5 truncate pl-4", {
            "font-medium": isExpanded || cardProductId === cardProduct.id,
          })}
        >
          {cardProduct.name}
          {cardProduct.name && cardProduct.name.length > 20 && (
            <div className="bg-ash fixed bottom-0 left-0 z-30 hidden rounded-tr-lg p-2 text-xs group-hover:block">
              {cardProduct.name}
            </div>
          )}
        </span>
      </div>
      {(isExpanded || cardProductId === cardProduct.id) && (
        <div className="flex grow flex-col pb-3">
          <nav aria-label="Sidebar" className="flex-1">
            <div className="space-y-0.5" role="group">
              {sidebarCardProductLinks.map((link) => (
                <SidebarLink key={link.text} {...link} />
              ))}
            </div>
          </nav>
        </div>
      )}
    </div>
  );
};
