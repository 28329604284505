import { FinancialAccountStatus } from "@bay1/sdk/generated/graphql";
import classNames from "classnames";
import React from "react";

import { formatFinancialAccountStatus } from "../helpers";

export const FinancialAccountStatusPill = ({
  status,
}: Readonly<{
  status?: FinancialAccountStatus;
}>): JSX.Element => (
  <div
    className={classNames(
      "inline-flex min-w-max items-center rounded-full px-1 py-0.5 pr-3 text-xs",
      {
        "bg-green-100": status === FinancialAccountStatus.ACTIVE,

        "bg-red-100":
          status === FinancialAccountStatus.CLOSED ||
          status === FinancialAccountStatus.PENDING_CLOSURE ||
          status === FinancialAccountStatus.SUSPENDED ||
          status === FinancialAccountStatus.UNDER_REVIEW,
      },
      {},
    )}
  >
    <div className="flex min-w-max items-center space-x-1.5">
      {status === FinancialAccountStatus.ACTIVE && (
        <img alt="" src="/img/approved-icon.svg" />
      )}
      {status === FinancialAccountStatus.CLOSED && (
        <img alt="" src="/img/decline-icon.svg" />
      )}

      {status === FinancialAccountStatus.PENDING_CLOSURE && (
        <img alt="" src="/img/pending-icon-red.svg" />
      )}
      {(status === FinancialAccountStatus.SUSPENDED ||
        status === FinancialAccountStatus.UNDER_REVIEW) && (
        <img alt="" src="/img/error-icon.svg" />
      )}
      <span
        data-testid="financialAccount::status"
        id={formatFinancialAccountStatus(status)}
      >
        {formatFinancialAccountStatus(status)}
      </span>
    </div>
  </div>
);
