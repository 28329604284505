"use client";

import type { FieldUserError } from "@bay1/sdk/generated/graphql";
import type { Maybe } from "graphql/jsutils/Maybe";
import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useMemo,
  useState,
} from "react";
import type { DeepReadonly } from "ts-essentials";

import { ToastQueueEntry } from "./Toast";

const errors = ["AccessDeniedError", "FieldUserError", "UserError"];
export interface ToastResponse {
  data: any;
  status: number;
}

export interface ToastState {
  toastsHistory: ToastQueueEntry[];
  toastsToShow: ToastQueueEntry[];
  setToastsToShow: Maybe<Dispatch<SetStateAction<ToastQueueEntry[]>>>;
  setToastsHistory: Maybe<Dispatch<SetStateAction<ToastQueueEntry[]>>>;
}

export interface ToastVariables {
  readonly toastState: ToastState;
  readonly mutationMiddleware?: (response: any) => void;
  readonly triggerToastMutation?: (data: any, success: boolean) => void;
}

export const ToastContext = createContext<ToastVariables>({
  toastState: {
    toastsHistory: [],
    toastsToShow: [],
    setToastsToShow: undefined,
    setToastsHistory: undefined,
  },
  mutationMiddleware: undefined,
  triggerToastMutation: undefined,
});

export const ToastContextProvider = ({
  children,
}: DeepReadonly<PropsWithChildren<unknown>>): JSX.Element => {
  const [toastsHistory, setToastsHistory] = useState<ToastQueueEntry[]>([]);
  const [toastsToShow, setToastsToShow] = useState<ToastQueueEntry[]>([]);

  const mutationMiddleware = (
    response: Readonly<{
      data: any;
      status: number;
      extensions: { requestId: string };
    }>,
  ) => {
    // eslint-disable-next-line fp/no-let
    let success = response.status === 200;

    const responses: any[] = Object.values(response.data);

    responses.forEach(
      (
        requestResponse: Readonly<{
          __typename: string;
          errors?: FieldUserError[];
        }>,
      ) => {
        if (
          errors.some((error) => requestResponse.__typename.includes(error)) &&
          requestResponse.errors
        ) {
          // eslint-disable-next-line fp/no-mutation
          success = false;
        } else {
          const responseId = response.extensions?.requestId as string;
          const foundInHistory = toastsHistory.find(
            (toast) => toast.id === responseId,
          );

          if (!foundInHistory || toastsHistory.length === 0) {
            const newToast: ToastQueueEntry = {
              response: response.data,
              success,
              seen: false,
              id: responseId,
            };
            setToastsToShow([...toastsToShow, newToast]);
          }
        }
      },
    );
  };

  const triggerToastMutation = (data: any, success: boolean) => {
    const newToast: ToastQueueEntry = {
      response: data,
      success,
      seen: false,
      id: new Date().toString(),
    };

    setToastsToShow([...toastsToShow, newToast]);
  };

  const toastProviderParameters = useMemo(
    () => ({
      toastState: {
        toastsToShow: toastsToShow,
        toastsHistory: toastsHistory,
        setToastsToShow: setToastsToShow,
        setToastsHistory: setToastsHistory,
      },
      mutationMiddleware,
      triggerToastMutation,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [toastsHistory, toastsToShow, setToastsToShow, setToastsHistory],
  );

  return (
    <ToastContext.Provider value={toastProviderParameters}>
      {children}
    </ToastContext.Provider>
  );
};
