import { CommonAppContext } from "@bay1/data";
import { MobileNavPopover } from "@bay1/ui/MobileNavPopover";
import {
  AccountDesktopNav,
  AccountMobileNav,
} from "@bay1/ui/components/AccountNav";
import SidebarNav from "@bay1/ui/components/SidebarNav";
import { Hamburger } from "@bay1/ui/components/nav/Hamburger";
import { NavContainer } from "@bay1/ui/components/nav/NavContainer";
import { OperationsBanner } from "@bay1/ui/components/nav/OperationsBanner";
import { OPS_URL } from "@bay1/ui/urlHelper";
import { deleteCookie } from "cookies-next";
import NextImage from "next/legacy/image";
import Link from "next/link";
import { useRouter } from "next/router";
import type { PropsWithChildren } from "react";
import { useCallback, useContext, useEffect, useState } from "react";
import type { DeepReadonly } from "ts-essentials";

import { SearchBar } from "./SearchBar";

export const DashboardNav = ({
  children,
}: DeepReadonly<PropsWithChildren<unknown>>): JSX.Element => {
  const { activeOrganization } = useContext(CommonAppContext);
  const [isOpen, setIsOpen] = useState(false);
  const router = useRouter();
  const toggleIsOpen = useCallback((): void => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const { cardProductId: cardProductIds } = router.query;

  const [cardProductId] = Array(cardProductIds).flat();

  const handleRouteToOps = () => {
    deleteCookie("ops-grantAdminUserAccess", {
      httpOnly: false,
      path: "/",
      secure: process.env.NEXT_PUBLIC_AUTH_COOKIE_SSL === "true",
      sameSite: "lax",
      domain: process.env.NEXT_PUBLIC_AUTH_COOKIE_DOMAIN ?? "localhost",
    });
    router.push(OPS_URL);
  };

  useEffect(() => {
    setIsOpen(false);
  }, [cardProductId]);

  return (
    <>
      <NavContainer>
        <div className="relative">
          {activeOrganization?.isAdminUserAccessOrg && (
            <OperationsBanner
              hasBackButton
              handleClickBackButton={handleRouteToOps}
            />
          )}
          <div className="flex items-center justify-between px-2 py-2 pr-3">
            {router.pathname.includes("/organizations") && (
              <>
                <div
                  className={`text-reset flex-none ${
                    router.pathname.includes("/organizations")
                      ? "mr-2 sm:mr-[138px]"
                      : "mr-2 sm:mr-6"
                  }`}
                >
                  <Link
                    href={{
                      pathname: "/",

                      query: {
                        activeOrganizationId: activeOrganization?.id,
                      },
                    }}
                    passHref
                  >
                    <span className="sr-only">Highnote</span>
                    {router.pathname.includes("/organizations") ? (
                      <span className="hidden h-[56px] pt-2 pl-2 sm:block">
                        <NextImage
                          alt="Highnote"
                          height={40}
                          src="/img/highnote-circle.svg"
                          width={40}
                        />
                      </span>
                    ) : (
                      <span className="hidden sm:block">
                        <NextImage
                          alt="Highnote"
                          className="hidden sm:block"
                          height={56}
                          src="/img/highnote.svg"
                          width={162}
                        />
                      </span>
                    )}
                    <span className="sm:hidden">
                      <NextImage
                        alt="Highnote"
                        className="sm:hidden"
                        height={40}
                        src="/img/highnote-circle.svg"
                        width={40}
                      />
                    </span>
                  </Link>
                </div>
                <div className="flex w-full items-center justify-between rounded pr-9 sm:pr-2 md:ml-14 md:pl-1.5">
                  <SearchBar />
                  <AccountDesktopNav />
                </div>
              </>
            )}
            {Boolean(
              router.pathname.includes("/profile") ||
                router.pathname.includes("plan-your-launch"),
            ) && (
              <>
                <div className="text-reset mr-2 flex-none sm:mr-6">
                  <Link
                    href={{
                      pathname: "/",

                      query: {
                        activeOrganizationId: activeOrganization?.id,
                      },
                    }}
                    passHref
                  >
                    <span className="sr-only">Highnote</span>
                    {router.pathname.includes("/organizations") ? (
                      <span className="hidden sm:block">
                        <NextImage
                          alt="Highnote"
                          height={40}
                          src="/img/highnote-circle.svg"
                          width={40}
                        />
                      </span>
                    ) : (
                      <span className="hidden sm:block">
                        <NextImage
                          alt="Highnote"
                          className="hidden sm:block"
                          height={56}
                          src="/img/highnote.svg"
                          width={162}
                        />
                      </span>
                    )}
                    <span className="sm:hidden">
                      <NextImage
                        alt="Highnote"
                        className="sm:hidden"
                        height={40}
                        src="/img/highnote-circle.svg"
                        width={40}
                      />
                    </span>
                  </Link>
                </div>
                <div className="flex w-full items-center justify-end rounded pr-9 sm:pr-2 md:ml-14 md:pl-1.5">
                  <AccountDesktopNav />
                </div>
              </>
            )}
          </div>
          <Hamburger onClick={toggleIsOpen} />
        </div>
        <MobileNavPopover isOpen={isOpen} onClick={toggleIsOpen}>
          <AccountMobileNav>
            {router.pathname.includes("/organizations") && (
              <SidebarNav isMobile>{children}</SidebarNav>
            )}
          </AccountMobileNav>
        </MobileNavPopover>
      </NavContainer>
    </>
  );
};
