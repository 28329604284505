import { AccountHolderVerificationResultCode } from "@bay1/sdk/generated/graphql";

export enum VerificationResultStatusForUI {
  SUCCESS = "SUCCESS",
  FAIL = "FAIL",
  WARNING = "WARNING",
  NOT_RAN = "NOT_RAN",
}

export type VerificationResultMap = Record<
  string,
  {
    [code in AccountHolderVerificationResultCode]?: [
      VerificationResultStatusForUI,
      string,
    ];
  }
>;

export const verificationResultMap: VerificationResultMap = {
  Name: {
    [AccountHolderVerificationResultCode.NAME_MATCH]: [
      VerificationResultStatusForUI.SUCCESS,
      "Matched",
    ],

    [AccountHolderVerificationResultCode.NAME_MISMATCH]: [
      VerificationResultStatusForUI.FAIL,
      "Did not match",
    ],

    [AccountHolderVerificationResultCode.BUSINESS_NAME_MATCH]: [
      VerificationResultStatusForUI.SUCCESS,
      "Matched",
    ],

    [AccountHolderVerificationResultCode.BUSINESS_NAME_MISMATCH]: [
      VerificationResultStatusForUI.FAIL,
      "Name did not match",
    ],
  },

  Device: {
    [AccountHolderVerificationResultCode.DEVICE_WARNING]: [
      VerificationResultStatusForUI.WARNING,
      "The IP Address used when opening the Application was unrecognized.",
    ],

    [AccountHolderVerificationResultCode.FOREIGN_DEVICE]: [
      VerificationResultStatusForUI.FAIL,
      "The IP Address used when opening the Application was not able to be verified.",
    ],
  },

  SSN: {
    [AccountHolderVerificationResultCode.SSN_WARNING]: [
      VerificationResultStatusForUI.WARNING,
      "The SSN/TID number provided may be incorrect.",
    ],

    [AccountHolderVerificationResultCode.SSN_MISKEY]: [
      VerificationResultStatusForUI.FAIL,
      "The SSN number was entered incorrectly.",
    ],

    [AccountHolderVerificationResultCode.SSN_MATCH]: [
      VerificationResultStatusForUI.SUCCESS,
      "Matched",
    ],

    [AccountHolderVerificationResultCode.SSN_MISMATCH]: [
      VerificationResultStatusForUI.FAIL,
      "The SSN number was unable to be verified.",
    ],
  },

  "Date of Birth": {
    [AccountHolderVerificationResultCode.DOB_MISKEY]: [
      VerificationResultStatusForUI.FAIL,
      "The date of birth was entered incorrectly",
    ],

    [AccountHolderVerificationResultCode.DOB_MATCH]: [
      VerificationResultStatusForUI.SUCCESS,
      "Matched",
    ],

    [AccountHolderVerificationResultCode.DOB_MISMATCH]: [
      VerificationResultStatusForUI.FAIL,
      "The date of birth provided did not match",
    ],
  },

  Email: {
    [AccountHolderVerificationResultCode.EMAIL_WARNING]: [
      VerificationResultStatusForUI.WARNING,
      "The email provided may be incorrect.",
    ],
  },

  Address: {
    [AccountHolderVerificationResultCode.ADDRESS_WARNING]: [
      VerificationResultStatusForUI.WARNING,
      "The address provided is related to or matches a Mail Drop or PO Box location",
    ],

    [AccountHolderVerificationResultCode.ADDRESS_MATCH]: [
      VerificationResultStatusForUI.SUCCESS,
      "Matched",
    ],

    [AccountHolderVerificationResultCode.ADDRESS_MISMATCH]: [
      VerificationResultStatusForUI.FAIL,
      "The address provided did not match",
    ],
  },

  "Fraud Check": {
    [AccountHolderVerificationResultCode.FRAUD_REVIEW]: [
      VerificationResultStatusForUI.WARNING,
      "The fraud check is under review.",
    ],

    [AccountHolderVerificationResultCode.FRAUD_RISK]: [
      VerificationResultStatusForUI.WARNING,
      "A potential fraud risk was discovered.",
    ],

    [AccountHolderVerificationResultCode.FRAUD_WARNING]: [
      VerificationResultStatusForUI.WARNING,
      "A potential fraud risk was discovered.",
    ],

    [AccountHolderVerificationResultCode.DENIED_FRAUD]: [
      VerificationResultStatusForUI.FAIL,
      "The verification failed due to fraud.",
    ],

    [AccountHolderVerificationResultCode.KYC_FRAUD_PASS]: [
      VerificationResultStatusForUI.SUCCESS,
      "The account holder is not associated with prior fraudulent activity.",
    ],
  },

  "Government Watchlist": {
    [AccountHolderVerificationResultCode.WATCHLIST_HIT]: [
      VerificationResultStatusForUI.FAIL,
      "There was an OFAC match.",
    ],

    [AccountHolderVerificationResultCode.WATCHLIST_WARNING]: [
      VerificationResultStatusForUI.WARNING,
      "There was a potential OFAC match.",
    ],
  },

  "Know Your Customer": {
    [AccountHolderVerificationResultCode.DENIED_KYC]: [
      VerificationResultStatusForUI.FAIL,
      "Know Your Customer (KYC) checks failed.",
    ],
  },

  Phone: {
    [AccountHolderVerificationResultCode.PHONE_MATCH]: [
      VerificationResultStatusForUI.SUCCESS,
      "Matched",
    ],

    [AccountHolderVerificationResultCode.PHONE_MISMATCH]: [
      VerificationResultStatusForUI.FAIL,
      "The phone number provided did not match",
    ],
  },

  "Business Verification": {
    [AccountHolderVerificationResultCode.BUSINESS_VERIFICATION_SCORE_PASSED]: [
      VerificationResultStatusForUI.SUCCESS,
      "Verified",
    ],

    [AccountHolderVerificationResultCode.BUSINESS_VERIFICATION_SCORE_FAILED]: [
      VerificationResultStatusForUI.FAIL,
      "Business data did not pass verification.",
    ],
  },

  FEIN: {
    [AccountHolderVerificationResultCode.FEIN_MATCH]: [
      VerificationResultStatusForUI.SUCCESS,
      "Matched",
    ],

    [AccountHolderVerificationResultCode.FEIN_MISMATCH]: [
      VerificationResultStatusForUI.FAIL,
      "FEIN did not match.",
    ],
  },

  "Representative Match": {
    [AccountHolderVerificationResultCode.REPRESENTATIVE_MATCH]: [
      VerificationResultStatusForUI.SUCCESS,
      "Matched - Primary Authorized Person",
    ],

    [AccountHolderVerificationResultCode.REPRESENTATIVE_MISMATCH]: [
      VerificationResultStatusForUI.FAIL,
      "The primary authorized person could not be verified as representative of the business.",
    ],
  },

  "Theft Check": {
    [AccountHolderVerificationResultCode.LOW_THEFT_SCORE]: [
      VerificationResultStatusForUI.SUCCESS,
      "Low probability of third party fraud.",
    ],

    [AccountHolderVerificationResultCode.MEDIUM_THEFT_SCORE]: [
      VerificationResultStatusForUI.WARNING,
      "Moderate probability of third party fraud.",
    ],

    [AccountHolderVerificationResultCode.HIGH_THEFT_SCORE]: [
      VerificationResultStatusForUI.FAIL,
      "High probability of third party fraud.",
    ],
  },

  "Synthetic Fraud Check": {
    [AccountHolderVerificationResultCode.LOW_ABUSE_SCORE]: [
      VerificationResultStatusForUI.SUCCESS,
      "Low probability of synthetic fraud.",
    ],

    [AccountHolderVerificationResultCode.MEDIUM_ABUSE_SCORE]: [
      VerificationResultStatusForUI.WARNING,
      "Moderate probability of synthetic fraud.",
    ],

    [AccountHolderVerificationResultCode.HIGH_ABUSE_SCORE]: [
      VerificationResultStatusForUI.FAIL,
      "High probability of synthetic fraud.",
    ],
  },
};
