import classNames from "classnames";
import React from "react";

import {
  TableHeaderAlignLeft,
  TableHeaderAlignRight,
  TableHeaderAlignRightByN,
} from ".";

export const Table = (
  props: Readonly<
    React.PropsWithChildren<{
      columns: ReadonlyArray<string>;
      alignRight?: boolean;
      alignRightByN?: number;
      noOverflow?: boolean;
    }>
  >,
): JSX.Element => {
  const {
    children,
    columns,
    alignRight,
    alignRightByN,
    noOverflow = false,
    ...rest
  } = props;
  return (
    <div
      className={classNames(
        "-mx-6 -my-2 flex min-w-full flex-col px-6 pt-2 align-middle lg:-mx-8 lg:px-8",
        {
          "overflow-auto": !noOverflow,
        },
      )}
      {...rest}
    >
      <table className="min-w-full border-collapse">
        {alignRight ? (
          <TableHeaderAlignRight columns={columns} />
        ) : alignRightByN ? (
          <TableHeaderAlignRightByN
            columns={columns}
            numberOfColumnsFromLeft={alignRightByN}
          />
        ) : (
          <TableHeaderAlignLeft columns={columns} />
        )}
        <tbody className="border-ash divide-none border-t">{children}</tbody>
      </table>
    </div>
  );
};
