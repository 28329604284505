import { ErrorMessage, FieldLabel } from "@bay1/ui";
import classNames from "classnames";
import { useFormikContext } from "formik";
import getDeep from "lodash.get";
import type { ChangeEvent } from "react";
import React, { useCallback } from "react";

interface FieldProps {
  name: string;
  required?: boolean;
  labelText: string;
  label?: JSX.IntrinsicElements["label"];
  transformValue?: (value: string) => string;
  disabled?: boolean;
}

type InputFieldProps = FieldProps & {
  input?: JSX.IntrinsicElements["input"];
  placeholder?: string;
  maxLength?: number;
  type?: string;
  shouldClearValue?: boolean;
  isTextArea?: boolean;
};

export const Field = ({
  disabled,
  input,
  label,
  labelText,
  maxLength,
  name,
  placeholder,
  required,
  transformValue,
  type,
  isTextArea,
}: Readonly<InputFieldProps>): JSX.Element => {
  const {
    errors,
    handleBlur,
    handleChange,
    isSubmitting,
    setFieldValue,
    touched,
    values,
  } = useFormikContext();
  const hasError = getDeep(touched, name) && getDeep(errors, name);

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      if (transformValue) {
        setFieldValue(name, transformValue(event.target.value));
      } else {
        handleChange(event);
      }
    },
    [handleChange, name, setFieldValue, transformValue],
  );

  const handleTextAreaInputChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>): void => {
      if (transformValue) {
        setFieldValue(name, transformValue(event.target.value));
      } else {
        handleChange(event);
      }
    },
    [handleChange, name, setFieldValue, transformValue],
  );

  if (isTextArea) {
    return (
      <>
        <FieldLabel
          hasError={hasError}
          isSubmitting={isSubmitting}
          label={label}
          labelText={labelText}
          name={name}
          required={required}
        />
        <textarea
          className={classNames(input?.className || "", {
            baseInputWithError: hasError,
            baseInput: !hasError,
            "bg-bone cursor-not-allowed border-none": disabled,
          })}
          data-testid={`form::input::${name}`}
          disabled={(disabled ?? false) || isSubmitting}
          id={name}
          maxLength={maxLength ?? 255}
          name={name}
          onBlur={handleBlur}
          onChange={handleTextAreaInputChange}
          placeholder={placeholder ?? ""}
          value={getDeep(values, name) || ""}
        />

        <ErrorMessage keyPath={name} />
      </>
    );
  }

  return (
    <>
      <FieldLabel
        hasError={hasError}
        isSubmitting={isSubmitting}
        label={label}
        labelText={labelText}
        name={name}
        required={required}
      />
      <input
        {...input}
        className={classNames(input?.className || "", {
          baseInputWithError: hasError,
          baseInput: !hasError,
          "bg-bone cursor-not-allowed border-none": disabled,
        })}
        data-testid={`form::input::${name}`}
        disabled={(disabled ?? false) || isSubmitting}
        id={name}
        maxLength={maxLength ?? 255}
        name={name}
        onBlur={handleBlur}
        onChange={handleInputChange}
        placeholder={placeholder ?? ""}
        type={type ?? "text"}
        value={getDeep(values, name) || ""}
      />

      <ErrorMessage keyPath={name} />
    </>
  );
};
