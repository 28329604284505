/* eslint-disable fp/no-mutation */
import {
  AccountClosureBlocker,
  AccountHolderApplicationStatusCode,
  AccountHolderVerificationResultCode,
  AccountHolderVerificationStatusCode,
  AccountHolderVerificationStatusReasonCode,
  AchOriginationType,
  Address,
  AdverseActionCode,
  AgentServicingApplicationDenyReason,
  AgentServicingApplicationReviewDecision,
  AgentServicingVerificationStatus,
  ApplicationDocumentUploadStatus,
  ApplicationDocumentUploadStatusInput,
  AuthorizedUserApplicationStatusCode,
  BalanceType,
  BillingCycleFilterInput,
  BusinessName,
  BusinessStructure,
  BusinessTitle,
  CalculatedTransferAmountType,
  CardArtDocument,
  CardArtDocumentType,
  CardDigitalWalletTokenState,
  CardFormFactor,
  CardProductVertical,
  CardProfileBankStatus,
  CardProfilePaymentNetworkStatus,
  CardProfileSetIntent,
  CardProfileSetNetwork,
  CardProfileStatus,
  CardProfileVendorStatus,
  CardTransactionProcessingType,
  CardUsage,
  CollaborativeAuthorizationEndpointStatus,
  CollaborativeAuthorizationResponseCode,
  CreditBalanceType,
  CreditPlanStatus,
  CreditPlanType,
  CreditRepaymentMethod,
  CreditStatementCyclePeriod,
  CreditStatementGracePeriodType,
  DeliveryAttemptStatus,
  DigitalWalletProvider,
  DistanceUnit,
  DocumentType,
  DocumentUploadLinkStatusCode,
  DocumentUploadSessionStatusCode,
  EmploymentStatus,
  ExternallyInitiatedAchHoldStatus,
  ExternallyInitiatedAchStatus,
  ExternallyInitiatedAchStatusReasonCode,
  ExternallyInitiatedTransferType,
  FeeScheduleStatus,
  FeeTransferEventFeeActivityType,
  FeeTransferEventFeeActivityTypeInput,
  FinancialAccountAttribute,
  FinancialAccountAttributeInput,
  FinancialAccountCloseReason,
  FinancialAccountClosureRequesterType,
  FinancialAccountStatus,
  IntegratorInitiatedAchHoldStatus,
  IntegratorInitiatedAchStatus,
  IntegratorInitiatedAchStatusReasonCode,
  IntegratorInitiatedTransferType,
  InterFinancialAccountTransferActivityType,
  InterFinancialAccountTransferStatus,
  InterFinancialAccountTransferStatusReasonCode,
  InterestRateType,
  Iso4217Alpha3CurrencyCode,
  LedgerName,
  ManualAdjustmentActivityType,
  MastercardFleetCreditOrDebitIndicator,
  MastercardFleetFuelBrand,
  MastercardFleetFuelServiceType,
  MastercardFleetProductCode,
  MastercardFleetProductType,
  MastercardFleetUnitOfMeasure,
  Maybe,
  MerchantCategory,
  Name,
  NotificationEventName,
  NotificationTargetStatus,
  PanEntryMode,
  PayOffType,
  PaymentCardChargebackStatus,
  PaymentCardDisputeCategoryType,
  PaymentCardDisputeStatus,
  PaymentCardGroupOrderStatus,
  PaymentCardOrderStatus,
  PaymentCardShippingMethod,
  PaymentCardStatus,
  PersonAccountHolderNameFilterInput,
  Phone,
  PhoneInput,
  PhoneLabel,
  PhysicalCardArtOrientation,
  PhysicalCardMaterial,
  PhysicalCardPersonalizationFormat,
  PhysicalCardPersonalizationLineType,
  PhysicalCardType,
  PhysicalCardVendorName,
  PinEntryMode,
  PointOfServiceCategory,
  RecurringAchTransferFrequencyCode,
  ReportStatus,
  ReportType,
  ScheduledTransferStatusCode,
  TerminalAttendance,
  TransactionEventFilter,
  TransactionEventResponseCode,
  TransferPurpose,
  UnderwriterVerificationStatusCode,
  DocumentType as UploadedDocumentType,
  VelocityRuleWindow,
  VirtualCardPersonalizationType,
  VisaAmountSignage,
  VisaDiscountTreatment,
  VisaFleetPurchaseIdentifierFormat,
  VisaFuelPurchaseType,
  VisaFuelServiceType,
  VisaFuelType,
  VisaFuelUnitOfMeasure,
  VisaItemCommodityCode,
  VisaLineItemDetailIndicator,
  VisaNonFuelProductCode,
  VisaTaxTreatment,
  WireStatusReasonCode,
  WireTransferStatus,
} from "@bay1/sdk/generated/graphql";
import type { LogEvent } from "auth0";
import { format } from "date-fns";
import Dinero, { Currency } from "dinero.js";
import type { ParsedUrlQuery } from "querystring";
import title from "title";
import type { DeepReadonly } from "ts-essentials";

import { TransactionEventNode } from "../../hooks/useCardProductTransactionEvents";
import type { USAccountHolderFromQuery } from "../../hooks/useFindAccountHolder";
import { TransferUnion } from "../../hooks/useTransfer";
import type { PartialMoneyFilterInput } from "../filters/transactionEvent/TransactionEventFilterDropdown";

export type { VerificationResultMap } from "./formatAccountHolderCardProductApplicationStatuses";
export {
  verificationResultMap,
  VerificationResultStatusForUI,
} from "./formatAccountHolderCardProductApplicationStatuses";

const numberToMonth = (number: string): string => {
  switch (number) {
    case "01":
      return "Jan";
    case "02":
      return "Feb";
    case "03":
      return "Mar";
    case "04":
      return "Apr";
    case "05":
      return "May";
    case "06":
      return "June";
    case "07":
      return "July";
    case "08":
      return "Aug";
    case "09":
      return "Sept";
    case "10":
      return "Oct";
    case "11":
      return "Nov";
    case "12":
      return "Dec";

    default:
      return "";
  }
};

const numberToFullMonth = (number: string): string => {
  switch (number) {
    case "01":
      return "January";
    case "02":
      return "February";
    case "03":
      return "March";
    case "04":
      return "April";
    case "05":
      return "May";
    case "06":
      return "June";
    case "07":
      return "July";
    case "08":
      return "August";
    case "09":
      return "September";
    case "10":
      return "October";
    case "11":
      return "November";
    case "12":
      return "December";

    default:
      return "";
  }
};

export function formatTransactionEventName(
  transactionEvent: Maybe<TransactionEventNode>,
): string {
  if (transactionEvent === undefined) {
    return "";
  }

  if (
    transactionEvent.transaction?.__typename === "CreditTransaction" &&
    transactionEvent.__typename === "ClearingEvent"
  ) {
    return "Refund";
  }

  if (
    transactionEvent.transaction?.__typename === "CreditTransaction" &&
    transactionEvent.__typename === "AuthorizationEvent"
  ) {
    return "Refund Authorization";
  }

  if (transactionEvent.__typename === "AuthorizationAndClearEvent") {
    return "Authorization and Clear";
  }

  if (transactionEvent.__typename === "BalanceInquiryEvent") {
    return "Balance Inquiry";
  }

  if (transactionEvent.__typename === "IssuerPreliminaryAuthorizationEvent") {
    return "Pre-Authorization";
  }

  return transactionEvent.__typename.replace("Event", "");
}

export function formatReportStatus(status: Maybe<ReportStatus>): string {
  switch (status) {
    case ReportStatus.COMPLETED:
      return "Completed";
    case ReportStatus.FAILED:
      return "Failed";
    case ReportStatus.IN_PROGRESS:
      return "In Progress";
    default:
      return "";
  }
}

export function formatCardProfileStatus(
  status: Maybe<CardProfileStatus>,
): string {
  return status ? title(status.replaceAll("_", " ")) : "";
}

export function formatDocumentUploadLinkStatus(
  status: Maybe<DocumentUploadLinkStatusCode>,
): string {
  return status ? title(status.replaceAll("_", " ")) : "";
}

export function formatCardArtDocumentType(
  documentType: Maybe<CardArtDocumentType>,
): string {
  return documentType ? title(documentType.replaceAll("_", " ")) : "";
}

export function formatCardArtDocumentUploadLinkStatus(
  status: Maybe<DocumentUploadLinkStatusCode>,
): string {
  return status ? title(status.replaceAll("_", " ")) : "";
}

export function formatCardArtDocumentUploadSessionStatus(
  status: Maybe<DocumentUploadSessionStatusCode>,
): string {
  return status ? title(status.replaceAll("_", " ")) : "";
}

export function formatCardProfileBankStatus(
  status: Maybe<CardProfileBankStatus>,
): string {
  return status ? title(status.replaceAll("_", " ")) : "";
}

export function formatCardProfilePaymentNetworkStatus(
  status: Maybe<CardProfilePaymentNetworkStatus>,
): string {
  return status ? title(status.replaceAll("_", " ")) : "";
}

export function formatCardProfileVendorStatus(
  status: Maybe<CardProfileVendorStatus>,
): string {
  return status ? title(status.replaceAll("_", " ")) : "";
}

export function formatCardProfileSetIntent(
  intent: Maybe<CardProfileSetIntent>,
): string {
  return intent ? title(intent.replaceAll("_", " ")) : "";
}

export function formatCardProfileSetNetwork(
  network: Maybe<CardProfileSetNetwork>,
): string {
  return network ? title(network.replaceAll("_", " ")) : "";
}

export function formatTransactionEventResponseCode(
  responseCode: Maybe<TransactionEventResponseCode>,
): string {
  if (!responseCode) {
    return "";
  }

  switch (responseCode) {
    case TransactionEventResponseCode.APPROVED_FOR_PARTIAL_AMOUNT:
      return "Partially Approved";
    case TransactionEventResponseCode.APPROVED_FOR_PURCHASE_AMOUNT_ONLY:
      return "Approved - Purchase Only";
    case TransactionEventResponseCode.BAD_CVV:
      return "Bad CVV";
    case TransactionEventResponseCode.BAD_CVV2:
      return "Bad CVV2";
    case TransactionEventResponseCode.BAD_CVC3:
      return "Bad CVC3";
    case TransactionEventResponseCode.INVALID_AUTHORIZATION_EXPIRATION:
      return "Invalid Authorization";
    case TransactionEventResponseCode.RE_ENTER_TRANSACTION:
      return "Re-enter Transaction";
    case TransactionEventResponseCode.CA_DECLINED:
      return "Declined by Collab Auth";

    default:
      return title(responseCode.replaceAll("_", " "));
  }
}

export const formatCardUsage = (usage: Maybe<CardUsage>): string => {
  switch (usage) {
    case CardUsage.MULTI_USE:
      return "Multi-use";
    case CardUsage.SINGLE_USE:
      return "Single-use";

    default:
      return "";
  }
};

export const formatCardVertical = (
  vertical: Maybe<CardProductVertical>,
): string => {
  switch (vertical) {
    case CardProductVertical.AP_INVOICE_AUTOMATION:
      return "AP Automation";
    case CardProductVertical.EARNED_WAGE_ACCESS:
      return "Earned Wage Access";
    case CardProductVertical.GENERAL_PURPOSE_RELOADABLE:
      return "Consumer Debit";
    case CardProductVertical.COMMERCIAL_DEBIT:
      return "Commercial Debit";
    case CardProductVertical.SECURED_COMMERCIAL_CREDIT:
      return "Secured Commercial Charge";
    case CardProductVertical.FLEET:
      return "Fleet";
    case CardProductVertical.COMMERCIAL_PREPAID:
      return "Commercial Prepaid";
    case CardProductVertical.CONSUMER_PREPAID:
      return "Consumer Prepaid";
    case CardProductVertical.COMMERCIAL_CREDIT_PAY_IN_FULL:
      return "Commercial Charge";
    case CardProductVertical.PAYROLL:
      return "Payroll";
    case CardProductVertical.CONSUMER_CREDIT:
      return "Consumer Credit";

    default:
      return "";
  }
};

export const formatSpendRuleTypes = (string: Maybe<string>): string => {
  switch (string) {
    case "MerchantCategorySpendRule":
      return "Category Code";
    case "MerchantCountrySpendRule":
      return "Country Code";
    case "AmountLimitSpendRule":
      return "Amount";
    case "CVVSpendRule":
      return "CVV";
    case "StreetAddressSpendRule":
      return "Street Address";
    case "MerchantIdentifierSpendRule":
      return "Merchant Identifier";
    case "PointOfServiceCategorySpendRule":
      return "Point of Service";
    case "CountLimitSpendRule":
      return "Count Limit";
    case "ConditionalRuleSetSpendRule":
      return "Conditional Rule Set";
    case "CardTransactionProcessingTypeConditionSpendRule":
      return "Card Transaction Processing Type";
    case "DepositAmountLimitSpendRule":
      return "Deposit Amount";
    case "DepositCountLimitSpendRule":
      return "Deposit Count Limit";
    case "DepositProcessingNetworkSpendRule":
      return "Deposit Processing Network";

    default:
      return "";
  }
};

export const formatRiskRuleTypes = (string: Maybe<string>): string => {
  switch (string) {
    case "AmountLimitRiskRule":
      return "Amount";
    case "CountLimitRiskRule":
      return "Count Limit";
    case "MerchantCategoryRiskRule":
      return "Category Code";
    case "MerchantCountryRiskRule":
      return "Country Code";
    case "CVVRiskRule":
      return "CVV";
    case "StreetAddressRiskRule":
      return "Street Address";
    case "MerchantIdentifierRiskRule":
      return "Merchant Identifier";
    case "PointOfServiceCategoryRiskRule":
      return "Point of Service";
    case "DepositAmountLimitRiskRule":
      return "Deposit Amount";
    case "DepositCountLimitRiskRule":
      return "Deposit Count Limit";
    case "DepositProcessingNetworkRiskRule":
      return "Deposit Processing Network";
    case "ConditionalRuleSetRiskRule":
      return "Conditional Rule Set";
    case "CardTransactionProcessingTypeConditionRiskRule":
      return "Card Transaction Processing Type";
    case "VelocityRiskRule":
      return "Velocity Risk Rule";
    case "UnusualVelocityOfAuthorizationsRiskRule":
      return "Unusual Velocity of Authorizations";
    case "LargeRefundRiskRule":
      return "Large Refund";
    case "ForcedPostTransactionRiskRule":
      return "Forced Post Transaction";
    case "UnmatchedRefundRiskRule":
      return "Unmatched Refund";
    case "ResponseCodeRiskRule":
      return "Response Code";
    case "MinimumDaysAccountOpenRiskRule":
      return "Minimum Days Account Open";
    case "MaximumDaysAccountOpenRiskRule":
      return "Maximum Days Account Open";
    case "CardTransactionEventTypeConditionRiskRule":
      return "Card Transaction Event Type";
    case "ActivityAfterDaysDormantRiskRule":
      return "Activity After Days Dormant";
    case "LargeACHDepositWithinDaysRiskRule":
      return "Large ACH Deposit Within Days";
    case "UnmatchedReversalRiskRule":
      return "Unmatched Reversal";
    case "GeolocationTrackingRiskRule":
      return "Geolocation Tracking";

    default:
      return "";
  }
};

export const formatRateTypes = (string: Maybe<string>): string => {
  switch (string) {
    case "FIXED":
      return "Fixed";
    case "VARIABLE":
      return "Variable";

    default:
      return "";
  }
};

export const formatAssessmentScheduleTypes = (
  string: Maybe<string>,
): string => {
  switch (string) {
    case "DAILY":
      return "Daily";
    case "STATEMENT":
      return "Statement";

    default:
      return "";
  }
};

export const formatAccrualTypes = (string: Maybe<string>): string => {
  switch (string) {
    case "COMPOUND":
      return "Compound";
    case "SIMPLE":
      return "Simple";

    default:
      return "";
  }
};
export const formatInterestAccrualMethodTypes = (
  string: Maybe<string>,
): string => {
  switch (string) {
    case "FIRST_DAY_OF_BILLING_PERIOD":
      return "First Day of Billing Period";
    case "PAYMENT_DUE_DATE":
      return "Payment Due Date";
    case "TRANSACTION_POST_DATE":
      return "Transaction Post Date";

    default:
      return "";
  }
};

export const stripPercents = (string: string): string => {
  return string.replace("%", "");
};

export const stripPercentsAndLimitDecimals = (string: string): string => {
  const withoutPercents = string.replace("%", "");
  const decimal_index = withoutPercents.indexOf(".");
  if (decimal_index > -1) {
    const decimals = withoutPercents.substring(
      decimal_index,
      withoutPercents.length + 1,
    );
    if (decimals.length > 4) {
      const asNumber = parseFloat(withoutPercents);
      return asNumber.toPrecision(4);
    }
  }
  return withoutPercents;
};

export const formatBillingCyclesTimeFilterFromQuery = (
  query: Readonly<ParsedUrlQuery>,
): Maybe<BillingCycleFilterInput> => {
  const statementPeriodStart = Array(query.statementPeriodStartOrEndBetween)
    .flat()[0]
    ?.split("Z:")[0];
  const statementPeriodEnd = Array(query.statementPeriodStartOrEndBetween)
    .flat()[0]
    ?.split("Z:")[1];

  if (!statementPeriodStart || !statementPeriodEnd) {
    return undefined;
  }

  return {
    statementPeriodStartOrEndBetween:
      statementPeriodStart && statementPeriodEnd
        ? {
            start: statementPeriodStart.concat("Z"),
            end: statementPeriodEnd,
          }
        : undefined,
  };
};

export const getUTCNowIn8061 = (date: Date): string => {
  //convert to double digit
  const monthZeroBased = date.getUTCMonth() + 1;
  const month = monthZeroBased.toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
  const day = date
    .getUTCDate()
    .toLocaleString("en-US", { minimumIntegerDigits: 2, useGrouping: false });
  const year = date.getUTCFullYear();
  //2023-01-15 format
  return `${year}-${month}-${day}`;
};

export const format8061ToMonthDayYear = (
  date: string,
  fullMonth?: boolean,
): string => {
  if (date.length <= 0) return "";
  //2023-05-03T04:00:00.000Z
  const sections = date.split("-");
  const year = sections[0];
  const month = fullMonth
    ? numberToFullMonth(sections[1])
    : numberToMonth(sections[1]);
  const day = sections[2].slice(0, 2);
  const strippedDay = day[0] === "0" ? day[1] : day;
  //May 2, 2023 format
  return `${month} ${strippedDay}, ${year}`;
};

export const formatMonthDayYearto8061 = (date: string): string => {
  //date: MM/DD/YYYY
  const pieces = date.split("/");
  //YYYY-MM-DD
  return `${pieces[2]}-${pieces[0]}-${pieces[1]}`;
};

export const formatSpendRuleResultTypes = (string: Maybe<string>): string => {
  switch (string) {
    case "MerchantCategorySpendRuleResult":
      return "Category Code";
    case "MerchantCountrySpendRuleResult":
      return "Country Code";
    case "AmountLimitSpendRuleResult":
      return "Amount";
    case "CVVSpendRuleResult":
      return "CVV";
    case "StreetAddressSpendRuleResult":
      return "Street Address";
    case "MerchantIdentifierSpendRuleResult":
      return "Merchant Identifier";

    default:
      return "";
  }
};

export const composeAccountHolderName = (
  name: Maybe<Name> | undefined,
): string => {
  if (!name) {
    return "";
  }

  const { givenName, middleName, familyName, title: nameTitle, suffix } = name;

  return `${nameTitle ?? ""} ${givenName} ${middleName ?? ""} ${familyName} ${
    suffix ?? ""
  }`;
};

export const composeBusinessAccountHolderName = (
  name: Maybe<BusinessName>,
): string => {
  if (!name) {
    return "";
  }

  const { doingBusinessAsName, legalBusinessName } = name;

  if (doingBusinessAsName === "") {
    return legalBusinessName;
  }

  return doingBusinessAsName ?? legalBusinessName;
};

const businessStructureTransforms: Record<
  "LLC",
  (businessStructure: BusinessStructure) => string
> = {
  LLC: () => "LLC",
};

type businessStructureKey = keyof typeof businessStructureTransforms;

export const presentBusinessStructure = (
  businessStructure?: BusinessStructure,
): string | undefined => {
  if (!businessStructure) {
    return undefined;
  }

  const shouldTransform = Object.keys(businessStructureTransforms).includes(
    businessStructure,
  );

  if (shouldTransform) {
    return businessStructureTransforms[
      businessStructure as businessStructureKey
    ](businessStructure);
  }
  return title(businessStructure.replaceAll("_", " "));
};

const jobTitleTransforms: Record<
  "CEO" | "CFO" | "COO",
  (jobTitle: BusinessTitle) => string
> = {
  CEO: () => "CEO",
  CFO: () => "CFO",
  COO: () => "COO",
};

type jobTitleKey = keyof typeof jobTitleTransforms;

export const presentJobTitle = (businessTitle?: BusinessTitle): string => {
  if (!businessTitle) {
    return "";
  }

  const shouldTransform =
    Object.keys(jobTitleTransforms).includes(businessTitle);

  if (shouldTransform) {
    return jobTitleTransforms[businessTitle as jobTitleKey](businessTitle);
  }
  return title(businessTitle.replaceAll("_", " "));
};

export const presentDateOfBirth = (dateOfBirth?: string) =>
  dateOfBirth === undefined
    ? "N/A"
    : format(new Date(dateOfBirth.replaceAll("-", "/")), "MMM do, yyyy");

export const formatPhoneNumberLabel = (
  phoneNumbers: Readonly<Maybe<Phone[]>>,
) => ((phoneNumbers || []).length > 1 ? "Phone Numbers" : "Phone Number");

export const formatPercentage = (value?: number) => {
  const percentSymbol = (value ?? "") === "" ? "" : "%";
  return `${value ?? ""}${percentSymbol}`;
};

export const presentAccountHolderType = (
  accountHolder: USAccountHolderFromQuery,
): string => {
  if (accountHolder?.__typename === "USPersonAccountHolder") {
    return "Person";
  }

  if (accountHolder?.__typename === "USBusinessAccountHolder") {
    return "Business";
  }

  return "";
};

export const composeLinePersonalization = (
  name: Maybe<Name> | undefined,
): string => {
  if (!name) {
    return "";
  }

  const { givenName, familyName, suffix } = name;

  if (suffix !== undefined && suffix !== "") {
    return `${givenName} ${familyName} ${suffix}`;
  }

  return `${givenName} ${familyName}`;
};

export const composeAddress = (address: Maybe<Address>): string => {
  if (!address) {
    return "";
  }

  const { streetAddress, extendedAddress, postalCode, region, locality } =
    address;

  if (extendedAddress !== "") {
    return `${streetAddress} ${extendedAddress}, ${locality}, ${region} ${postalCode}`;
  }

  return `${streetAddress}, ${locality}, ${region} ${postalCode}`;
};

export const composeLocation = (address?: Address) => {
  if (!address) {
    return undefined;
  }

  const locality = address.locality ?? "";
  const separator = locality === "" ? "" : ", ";

  return `${title(locality)}${separator}${address.region ?? ""}`;
};

export const formatMerchantDetail = (
  detail: Maybe<string>,
  defaultValue = "N/A",
): string => detail || defaultValue;

export const formatAccountHolderApplicationStatusCode = (
  status?: AccountHolderApplicationStatusCode,
): string => {
  switch (status) {
    case AccountHolderApplicationStatusCode.APPROVED:
      return "Approved";
    case AccountHolderApplicationStatusCode.IN_REVIEW:
      return "In Review";
    case AccountHolderApplicationStatusCode.PENDING:
      return "Pending";
    case AccountHolderApplicationStatusCode.DENIED:
      return "Denied";
    case AccountHolderApplicationStatusCode.CLOSED:
      return "Closed";

    default:
      return "";
  }
};

export const formatAccountHolderVerificationStatusReasonCode = (
  status?: AccountHolderVerificationStatusReasonCode,
): string => {
  switch (status) {
    case AccountHolderVerificationStatusReasonCode.DOCUMENT_UPLOAD_REQUIRED:
      return "Document Upload Required";
    case AccountHolderVerificationStatusReasonCode.IN_REVIEW:
      return "In Review";
    case AccountHolderVerificationStatusReasonCode.PENDING:
      return "Pending";
    case AccountHolderVerificationStatusReasonCode.DENIED:
      return "Denied";
    case AccountHolderVerificationStatusReasonCode.KBA_REQUIRED:
      return "KBA Required";
    case AccountHolderVerificationStatusReasonCode.PASSED:
      return "Passed";
    case AccountHolderVerificationStatusReasonCode.REVIEW_REQUIRED:
      return "Review Required";

    default:
      return "";
  }
};

export const formatAccountHolderVerificationStatusCode = (
  status?: AccountHolderVerificationStatusCode,
): string => {
  switch (status) {
    case AccountHolderVerificationStatusCode.PASSED:
      return "Passed";
    case AccountHolderVerificationStatusCode.PENDING:
      return "Pending";
    case AccountHolderVerificationStatusCode.DENIED:
      return "Denied";

    default:
      return "";
  }
};

export const formatAccountHolderVerificationReason = (
  reason?: AccountHolderVerificationStatusReasonCode,
): string => {
  switch (reason) {
    case AccountHolderVerificationStatusReasonCode.DENIED:
      return "Denied";
    case AccountHolderVerificationStatusReasonCode.DOCUMENT_UPLOAD_REQUIRED:
      return "Document Upload(s) Required";
    case AccountHolderVerificationStatusReasonCode.IN_REVIEW:
      return "In Review";
    case AccountHolderVerificationStatusReasonCode.KBA_REQUIRED:
      return "KBA Required";
    case AccountHolderVerificationStatusReasonCode.PASSED:
      return "Passed";
    case AccountHolderVerificationStatusReasonCode.PENDING:
      return "Pending";
    case AccountHolderVerificationStatusReasonCode.REVIEW_REQUIRED:
      return "Review Required";

    default:
      return "";
  }
};

export const formatUnderwriterVerificationStatusCode = (
  status?: UnderwriterVerificationStatusCode,
): string => {
  switch (status) {
    case UnderwriterVerificationStatusCode.PASSED:
      return "Passed";
    case UnderwriterVerificationStatusCode.PENDING:
      return "Pending";
    case UnderwriterVerificationStatusCode.IN_REVIEW:
      return "In Review";
    case UnderwriterVerificationStatusCode.DENIED:
      return "Denied";

    default:
      return "";
  }
};

export const formatPhoneLabel = (label?: PhoneLabel): string => {
  switch (label) {
    case PhoneLabel.HOME:
      return "Home";
    case PhoneLabel.MOBILE:
      return "Mobile";
    case PhoneLabel.WORK:
      return "Work";

    default:
      return "";
  }
};

export const formatEmploymentStatus = (status?: EmploymentStatus): string => {
  switch (status) {
    case EmploymentStatus.EMPLOYED:
      return "Employed";
    case EmploymentStatus.OTHER:
      return "Other";
    case EmploymentStatus.RETIRED:
      return "Retired";
    case EmploymentStatus.SELF_EMPLOYED:
      return "Self Employed";
    case EmploymentStatus.STUDENT:
      return "Student";
    case EmploymentStatus.UNEMPLOYED:
      return "Unemployed";

    default:
      return "";
  }
};

export const formatPaymentCardStatus = (status?: PaymentCardStatus): string => {
  switch (status) {
    case PaymentCardStatus.ACTIVATION_REQUIRED:
      return "Activation Required";
    case PaymentCardStatus.ACTIVE:
      return "Active";
    case PaymentCardStatus.SUSPENDED:
      return "Suspended";
    case PaymentCardStatus.CLOSED:
      return "Closed";

    default:
      return "";
  }
};

export const formatNotificationTargetStatus = (
  status?: NotificationTargetStatus,
): string => {
  switch (status) {
    case NotificationTargetStatus.ACTIVE:
      return "Active";
    case NotificationTargetStatus.DEACTIVATED:
      return "Deactivated";
    case NotificationTargetStatus.PENDING_VERIFICATION:
      return "Pending";
    case NotificationTargetStatus.ACTIVATION_FAILED:
      return "Activation Failed";

    default:
      return "";
  }
};

export function formatNotificationEventName(
  name: Maybe<NotificationEventName>,
): string {
  return name ? title(name.replaceAll("_", " ")) : "";
}

export const formatCollabAuthEndpointStatus = (
  status?: CollaborativeAuthorizationEndpointStatus,
): string => {
  switch (status) {
    case CollaborativeAuthorizationEndpointStatus.ACTIVE:
      return "Active";
    case CollaborativeAuthorizationEndpointStatus.DEACTIVATED:
      return "Deactivated";
    case CollaborativeAuthorizationEndpointStatus.PENDING_VERIFICATION:
      return "Pending";
    case CollaborativeAuthorizationEndpointStatus.ACTIVATION_FAILED:
      return "Activation Failed";

    default:
      return "";
  }
};

export const formatTransactionEventFilter = (
  status?: TransactionEventFilter,
): string => {
  switch (status) {
    case TransactionEventFilter.AUTHORIZATION_EVENT:
      return "Authorization";
    case TransactionEventFilter.REVERSAL_EVENT:
      return "Reversal";
    case TransactionEventFilter.CLEARING_EVENT:
      return "Clearing";
    case TransactionEventFilter.BALANCE_INQUIRY_EVENT:
      return "Balance Inquiry";
    case TransactionEventFilter.AUTHORIZATION_AND_CLEAR_EVENT:
      return "Authorization and Clear";
    case TransactionEventFilter.ISSUER_PRELIMINARY_AUTHORIZATION_EVENT:
      return "Pre-Authorization";
    case TransactionEventFilter.VERIFICATION_EVENT:
      return "Verification";

    default:
      return "";
  }
};

export const formatShippingMethod = (
  method: Maybe<PaymentCardShippingMethod>,
): string => {
  switch (method) {
    case PaymentCardShippingMethod.USPS_GROUND:
      return "USPS Ground";
    case PaymentCardShippingMethod.USPS_PRIORITY:
      return "USPS Priority";
    case PaymentCardShippingMethod.USPS_EXPRESS:
      return "USPS Express";
    case PaymentCardShippingMethod.SAME_DAY_USPS_GROUND:
      return "Same-Day USPS Ground";
    case PaymentCardShippingMethod.SAME_DAY_USPS_PRIORITY:
      return "Same-Day USPS Priority";
    case PaymentCardShippingMethod.UPS_GROUND:
      return "UPS Ground";
    case PaymentCardShippingMethod.UPS_SECOND_DAY:
      return "UPS Second Day";
    case PaymentCardShippingMethod.UPS_NEXT_DAY:
      return "UPS Next Day";
    case PaymentCardShippingMethod.SAME_DAY_UPS_GROUND:
      return "Same-Day UPS Ground";
    case PaymentCardShippingMethod.SAME_DAY_UPS_SECOND_DAY:
      return "Same-Day UPS Second Day";
    case PaymentCardShippingMethod.SAME_DAY_UPS_NEXT_DAY:
      return "Same-Day UPS Next Day";
    case PaymentCardShippingMethod.FEDEX_ONE_RATE:
      return "FedEx One Rate";
    case PaymentCardShippingMethod.FEDEX_OVERNIGHT:
      return "FedEx Overnight";
    default:
      return "";
  }
};

export const formatFormFactor = (factor?: CardFormFactor): string => {
  switch (factor) {
    case CardFormFactor.VIRTUAL:
      return "Virtual";
    case CardFormFactor.PHYSICAL:
      return "Physical";

    default:
      return "";
  }
};

export const formatLedgerName = (name: Maybe<LedgerName>): string => {
  if (!name) {
    return "";
  }

  if (name === LedgerName.OUTSTANDING_BALANCE_PAYABLE) {
    return "Outstanding Balance";
  }

  if (name === LedgerName.ACH_PENDING_SETTLEMENT) {
    return "ACH Pending Settlement";
  }

  if (name === LedgerName.ACH_PENDING_SETTLEMENT_OUT) {
    return "ACH Pending Settlement Out";
  }

  return title(name.replaceAll("_", " "));
};

export const formatAmount = (
  number: number,
  fourImpliedDecimalPlaces?: boolean,
): string => {
  if (fourImpliedDecimalPlaces) {
    return (number / 10000).toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
  return (number / 100).toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const formatCurrencyCodeSymbol = (
  currencyCode: Maybe<string>,
): string => {
  switch (currencyCode) {
    case Iso4217Alpha3CurrencyCode.USD:
    case Iso4217Alpha3CurrencyCode.AUD:
    case Iso4217Alpha3CurrencyCode.CAD:
    case Iso4217Alpha3CurrencyCode.HKD:
    case Iso4217Alpha3CurrencyCode.MXN:
    case Iso4217Alpha3CurrencyCode.SGD:
      return "$";
    case Iso4217Alpha3CurrencyCode.EUR:
      return "€";
    case Iso4217Alpha3CurrencyCode.GBP:
      return "£";
    case Iso4217Alpha3CurrencyCode.JPY:
    case Iso4217Alpha3CurrencyCode.CNY:
      return "¥";

    default:
      return "$";
  }
};

export const getCurrencyDecimals = (currencyCode: string) => {
  const hasZeroDecimals =
    currencyCode === "CVE" ||
    currencyCode === "DJF" ||
    currencyCode === "GNF" ||
    currencyCode === "IDR" ||
    currencyCode === "JPY" ||
    currencyCode === "KMF" ||
    currencyCode === "KRW" ||
    currencyCode === "PYG" ||
    currencyCode === "RWF" ||
    currencyCode === "UGX" ||
    currencyCode === "VND" ||
    currencyCode === "VUV" ||
    currencyCode === "XAF" ||
    currencyCode === "XOF" ||
    currencyCode === "XPF";

  const hasThreeDecimals =
    currencyCode === "BHD" ||
    currencyCode === "IQD" ||
    currencyCode === "JOD" ||
    currencyCode === "KWD" ||
    currencyCode === "LYD" ||
    currencyCode === "OMR" ||
    currencyCode === "TND";

  if (hasZeroDecimals) {
    return 0;
  } else if (hasThreeDecimals) {
    return 3;
  } else {
    return 2;
  }
};

export const formatCurrencyAmount = (
  amount: number,
  currencyCode: Maybe<string>,
  truncateDecimals: boolean = false,
  fourImpliedDecimals: boolean = false,
) => {
  const currencyDecimals = getCurrencyDecimals(currencyCode ?? "USD");

  const currencyAmount = Dinero({
    amount: amount,
    currency: (currencyCode ?? "USD") as Currency,
    precision: fourImpliedDecimals ? currencyDecimals + 2 : currencyDecimals,
  });

  if (truncateDecimals) {
    return currencyAmount.toFormat("$0,0");
  }

  switch (currencyDecimals) {
    case 0: {
      return currencyAmount.toFormat("$0,0");
    }
    case 3: {
      return currencyAmount.toFormat("$0,0.000");
    }
    default: {
      return currencyAmount.toFormat("$0,0.00");
    }
  }
};

export const formatQuantityAmount = (
  number: number,
  impliedFourDecimals: boolean = false,
) => {
  const denominator = impliedFourDecimals ? 10000 : 100;

  return (number / denominator).toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const formatPercentAmount = (
  number: number,
  impliedFourDecimals: boolean = false,
) => {
  return `${formatQuantityAmount(number, impliedFourDecimals)}%`;
};

export const formatAmountSymbol = (
  string: Maybe<keyof PartialMoneyFilterInput>,
): string => {
  switch (string) {
    case "equals":
      return "= ";
    case "greaterThan":
      return "> ";
    case "greaterThanOrEquals":
      return ">= ";
    case "lessThan":
      return "< ";
    case "lessThanOrEquals":
      return "<= ";

    default:
      return "";
  }
};

// Initial code taken from commit 4bf87c0, updated via our linting rules.
export const formatSSN = (ssn: string): string => {
  const formattedSSN = ssn
    .replace(/\D/gu, "")
    .replace(/^(\d{3})/u, "$1-")
    .replace(/-(\d{2})/u, "-$1-")
    .replace(/(\d)-(\d{4}).*/u, "$1-$2");
  return formattedSSN.slice(0, 11);
};

export const formatPhoneNumber = (phone: Maybe<string>): string => {
  if (phone === undefined) {
    return "";
  }
  const formattedPhoneNumber = phone
    .replace(/[^\d-]/gu, "")
    .replace(/^(\d{3})-?(\d{1,3})/u, "$1-$2")
    .replace(/^(\d{3})-?(\d{3})-?(\d{1,4})/u, "$1-$2-$3");
  return formattedPhoneNumber.slice(0, 12);
};

export const formatPostalCode = (code: string): string => {
  const formattedPostalCode = code
    .replace(/[^\d-]/gu, "")
    .replace(/^(\d{5})-?(\d{1,4})/u, "$1-$2");
  return formattedPostalCode.slice(0, 10);
};

export const formatCustomDate = (date: Maybe<string>): string => {
  if (date === undefined) {
    return "";
  }
  const formattedDate = date
    .replace(/[^\d-]/gu, "")
    .replace(/^(\d{2})-?(\d{1,2})/u, "$1-$2")
    .replace(/^(\d{2})-?(\d{2})-?(\d{1,4})/u, "$1-$2-$3")
    .replace("-", "/")
    .replace("-", "/");
  return formattedDate.slice(0, 8);
};

export const composeAccountHolderNameFromFilter = (
  name: DeepReadonly<PersonAccountHolderNameFilterInput> | undefined,
): string => {
  if (!name) {
    return "";
  }
  const { givenName, middleName, familyName, title: nameTitle, suffix } = name;

  return `${nameTitle?.soundsLike ?? ""} ${givenName?.soundsLike ?? ""} ${
    middleName?.soundsLike ?? ""
  } ${familyName?.soundsLike ?? ""} ${suffix?.soundsLike ?? ""}`;
};

export const formatEmployerIdentificationNumber = (value: string): string => {
  const employerIdentificationNumber = value.replace(/\D/gu, "");

  const employerIdentificationNumberLength =
    employerIdentificationNumber.length;

  if (employerIdentificationNumberLength < 3) {
    return employerIdentificationNumber;
  }

  return `${employerIdentificationNumber.slice(
    0,
    2,
  )}-${employerIdentificationNumber.slice(2)}`;
};

export const formatAccountHolderNameFilterInput = (
  name: string,
): Maybe<PersonAccountHolderNameFilterInput> => {
  const splitBySpaces = name.split(" ");

  if (splitBySpaces.length === 5) {
    return {
      title: { soundsLike: [splitBySpaces[0]] },
      givenName: { soundsLike: [splitBySpaces[1]] },
      middleName: { soundsLike: [splitBySpaces[2]] },
      familyName: { soundsLike: [splitBySpaces[3]] },
      suffix: { soundsLike: [splitBySpaces[4]] },
    };
  }

  if (splitBySpaces.length === 2) {
    return {
      givenName: { soundsLike: [splitBySpaces[0]] },
      familyName: { soundsLike: [splitBySpaces[1]] },
    };
  }

  if (splitBySpaces.length === 1) {
    return {
      givenName: { soundsLike: [splitBySpaces[0]] },
    };
  }

  if (splitBySpaces.length === 3) {
    return {
      givenName: { soundsLike: [splitBySpaces[0]] },
      middleName: { soundsLike: [splitBySpaces[1]] },
      familyName: { soundsLike: [splitBySpaces[2]] },
    };
  }

  return undefined;
};

export const formatExpirationDate = (
  expirationMonth: Maybe<string>,
  expirationYear: Maybe<string>,
) => {
  if (expirationMonth === undefined || expirationYear === undefined) {
    return "";
  }

  return `${expirationMonth}/${expirationYear}`;
};

export const formatDate = (
  date: string,
  includeTimeStamp?: boolean,
): string => {
  const [year, month, day] = date.split("T")[0].split("-");
  const timeStamp = includeTimeStamp
    ? `${
        new Date(date)
          .toLocaleString("en-us", { timeZone: "UTC" })
          .split(", ")[1]
      }`
    : "";

  return day.startsWith("0")
    ? `${numberToMonth(month)} ${day[1]}, ${year} ${timeStamp}`
    : `${numberToMonth(month)} ${day}, ${year} ${timeStamp}`;
};

export const formatScheduledTransferStatus = (
  status: Maybe<ScheduledTransferStatusCode>,
): string => (status ? title(status) : "");

export const formatCalculatedType = (
  calculatedType: Maybe<CalculatedTransferAmountType>,
): string => {
  switch (calculatedType) {
    case CalculatedTransferAmountType.OUTSTANDING_BALANCE:
      return "Balance";
    case CalculatedTransferAmountType.OUTSTANDING_STATEMENT_BALANCE:
      return "Statement Balance";

    default:
      return "";
  }
};

export const formatRecurringACHTransferFrequency = (
  frequency: Maybe<RecurringAchTransferFrequencyCode>,
): string => (frequency ? title(frequency) : "");

export const formatPointsLedger = (number: number): string =>
  number.toLocaleString("en-US");

export const formatIntegratorInitiatedAchStatusCode = (
  status: Maybe<IntegratorInitiatedAchStatus>,
): string => (status ? title(status) : "");

export const formatExternallyInitiatedAchStatusCode = (
  status: Maybe<ExternallyInitiatedAchStatus>,
): string => (status ? title(status) : "");

export const formatWireTransferStatus = (
  status: Maybe<WireTransferStatus>,
): string => (status ? title(status) : "");

export const formatInterFinancialAccountTransferStatus = (
  status: Maybe<InterFinancialAccountTransferStatus>,
): string => (status ? title(status) : "");

export const formatExternallyInitiatedAchHoldStatus = (
  status: Maybe<ExternallyInitiatedAchHoldStatus>,
): string => (status ? title(status.replaceAll("_", " ")) : "");

export const formatIntegratorInitiatedAchHoldStatus = (
  status: Maybe<IntegratorInitiatedAchHoldStatus>,
): string => (status ? title(status.replaceAll("_", " ")) : "");

export const formatPhoneNumberSearch = (phone: string): string => {
  const formattedPhoneNumber = phone
    .replace(/[^\d-]/gu, "")
    .replace(/^(\d{3})-?(\d{1,3})/u, "$1-$2")
    .replace(/^(\d{3})-?(\d{3})-?(\d{1,4})/u, "$1-$2-$3");
  return formattedPhoneNumber.slice(0, 12);
};

export const formatLast4Search = (last4: string): string =>
  last4.slice(6).replace(/\D/gu, "").slice(0, 4);

export const formatDocumentType = (type: Maybe<UploadedDocumentType>): string =>
  type ? title(type.replaceAll("_", " ")) : "";

export const formatUploadedDocumentStatus = (
  status: Maybe<ApplicationDocumentUploadStatus>,
): string => (status ? title(status.replaceAll("_", " ")) : "");

export const formatFinancialAccountStatus = (
  status: Maybe<FinancialAccountStatus>,
): string => {
  switch (status) {
    case FinancialAccountStatus.ACTIVE:
      return "Active";
    case FinancialAccountStatus.CLOSED:
      return "Closed";
    case FinancialAccountStatus.PENDING_CLOSURE:
      return "Pending Closure";
    case FinancialAccountStatus.SUSPENDED:
      return "Suspended";
    case FinancialAccountStatus.UNDER_REVIEW:
      return "Under Review";

    default:
      return "";
  }
};

export const formatLocationInfo = (
  locationInfo: LogEvent["location_info"],
): string => {
  if (!locationInfo) {
    return "";
  }

  return `${locationInfo.city_name}, ${locationInfo.country_code}`;
};

export const formatDigitalWalletCardTokenStatus = (
  status: Maybe<CardDigitalWalletTokenState>,
): string => {
  switch (status) {
    case CardDigitalWalletTokenState.ACTIVE:
      return "Active";
    case CardDigitalWalletTokenState.REQUESTED:
      return "Requested";
    case CardDigitalWalletTokenState.SUSPENDED:
      return "Suspended";
    case CardDigitalWalletTokenState.TERMINATED:
      return "Terminated";
    default:
      return "";
  }
};

export const formatGroupOrderStatusCode = (
  status?: PaymentCardGroupOrderStatus,
): string => {
  switch (status) {
    case PaymentCardGroupOrderStatus.NEW:
      return "New";
    case PaymentCardGroupOrderStatus.PENDING:
      return "Pending";
    case PaymentCardGroupOrderStatus.SENT_TO_PRINTER:
      return "Sent To Printer";
    case PaymentCardGroupOrderStatus.APPROVED:
      return "Approved";
    case PaymentCardGroupOrderStatus.CANCELED:
      return "Canceled";
    case PaymentCardGroupOrderStatus.SHIPPED:
      return "Shipped";
    case PaymentCardGroupOrderStatus.SHIP_FAILED:
      return "Ship Failed";

    default:
      return "";
  }
};

export const formatRequestedShipDate = (shipDate: Maybe<string>): string => {
  if (!shipDate || shipDate === "") {
    return "";
  }

  const [year, month, day] = shipDate.split("T")[0].split("-");

  return day.startsWith("0")
    ? `${numberToMonth(month)} ${day[1]}, ${year}`
    : `${numberToMonth(month)} ${day}, ${year}`;
};

export const formatPaymentCardOrderStatus = (
  status: Maybe<PaymentCardOrderStatus>,
): string => {
  switch (status) {
    case PaymentCardOrderStatus.NEW:
      return "New";
    case PaymentCardOrderStatus.SENT_TO_PRINTER:
      return "Sent To Printer";
    case PaymentCardOrderStatus.APPROVED:
      return "Approved";
    case PaymentCardOrderStatus.CANCELED:
      return "Canceled";
    case PaymentCardOrderStatus.SHIPPED:
      return "Shipped";
    case PaymentCardOrderStatus.SHIP_FAILED:
      return "Ship Failed";

    default:
      return "";
  }
};

export const formatVelocityRuleWindow = (
  window: Maybe<VelocityRuleWindow>,
): string => {
  switch (window) {
    case VelocityRuleWindow.DAILY:
      return "Daily";
    case VelocityRuleWindow.WEEKLY:
      return "Weekly";
    case VelocityRuleWindow.MONTHLY:
      return "Monthly";
    case VelocityRuleWindow.NINETY_DAYS:
      return "Ninety Days";
    case VelocityRuleWindow.YEARLY:
      return "Yearly";
    case VelocityRuleWindow.PER_TRANSACTION:
      return "Per Transaction";
    default:
      return "";
  }
};

export const formatVelocityRuleAdverbToNoun = (
  window: Maybe<VelocityRuleWindow>,
): string => {
  switch (window) {
    case VelocityRuleWindow.DAILY:
      return "day";
    case VelocityRuleWindow.WEEKLY:
      return "week";
    case VelocityRuleWindow.MONTHLY:
      return "month";
    case VelocityRuleWindow.NINETY_DAYS:
      return "90 days";
    case VelocityRuleWindow.YEARLY:
      return "year";
    case VelocityRuleWindow.PER_TRANSACTION:
      return "transaction";
    default:
      return "";
  }
};

export const formatTruncateRuleName = (name: Maybe<string | null>): string => {
  if (name && name.length > 30) {
    return name.substring(0, 30) + "...";
  }
  if (name) {
    return name;
  }
  return "";
};
export const formatCardTransactionProcessingType = (
  processingType: Maybe<CardTransactionProcessingType>,
): string => (processingType ? title(processingType.replaceAll("_", " ")) : "");

export const formatPointOfServiceDetailsCategory = (
  category: Maybe<PointOfServiceCategory>,
): string => (category ? title(category.replaceAll("_", " ")) : "Not Provided");

export const formatPanEntryMode = (entrymode: Maybe<PanEntryMode>): string =>
  entrymode ? title(entrymode.replaceAll("_", " ")) : "Not Provided";

export const formatPinEntryMode = (entrymode: Maybe<PinEntryMode>): string =>
  entrymode ? title(entrymode.replaceAll("_", " ")) : "Not Provided";

export const formatTerminalAttendance = (
  terminalAttendance: Maybe<TerminalAttendance>,
): string =>
  terminalAttendance
    ? title(terminalAttendance.replaceAll("_", " "))
    : "Not Provided";

export const formatFuelType = (fuelType: Maybe<VisaFuelType>): string =>
  fuelType ? title(fuelType.replaceAll("_", " ")) : "Not Provided";

export const formatFuelPurchaseType = (
  fuelPurchaseType: Maybe<VisaFuelPurchaseType>,
): string =>
  fuelPurchaseType
    ? title(fuelPurchaseType.replaceAll("_", " "))
    : "Not Provided";

export const formatFuelServiceType = (
  fuelServiceType: Maybe<VisaFuelServiceType>,
): string =>
  fuelServiceType
    ? title(fuelServiceType.replaceAll("_", " "))
    : "Not Provided";

export const formatFuelUnitOfMeasure = (
  unitOfMeasure: Maybe<VisaFuelUnitOfMeasure>,
  isPerUnit?: boolean,
): string => {
  if (!unitOfMeasure) {
    return "";
  }

  if (isPerUnit ?? false) {
    return unitOfMeasure === VisaFuelUnitOfMeasure.CHARGING_MINUTES
      ? "Charging Minute"
      : title(unitOfMeasure.replaceAll("_", " "));
  }

  return unitOfMeasure === VisaFuelUnitOfMeasure.CHARGING_MINUTES
    ? "Charging Minutes"
    : `${title(unitOfMeasure.replaceAll("_", " "))}`.concat("s");
};

export const formatNonFuelProductCode = (
  productCode: Maybe<VisaNonFuelProductCode>,
): string =>
  productCode ? title(productCode.replaceAll("_", " ")) : "Not Provided";

export const formatPurchaseIdentifierFormat = (
  purchaseIdentifierFormat: Maybe<VisaFleetPurchaseIdentifierFormat>,
): string =>
  purchaseIdentifierFormat
    ? title(purchaseIdentifierFormat.replaceAll("_", " "))
    : "";

export const formatAgentServicingVerificationStatus = (
  status?: AgentServicingVerificationStatus,
): string => {
  switch (status) {
    case AgentServicingVerificationStatus.PASSED:
      return "Passed";
    case AgentServicingVerificationStatus.DENIED:
      return "Denied";
    default:
      return "";
  }
};

export const formatAgentServicingApplicationReviewDecision = (
  decision?: AgentServicingApplicationReviewDecision,
): string => {
  switch (decision) {
    case AgentServicingApplicationReviewDecision.APPROVED:
      return "Approved";
    case AgentServicingApplicationReviewDecision.DENIED:
      return "Denied";
    default:
      return "";
  }
};

export const formatAgentServicingApplicationDenyReason = (
  reason?: AgentServicingApplicationDenyReason,
): string => {
  if (!reason) {
    return "";
  }
  const withoutPrefix = reason.split("DENIED_DUE_TO_")[1];
  return title(withoutPrefix.replaceAll("_", " "));
};

export const formatMerchantDetailCategory = (
  category: Maybe<MerchantCategory>,
): string => (category ? title(category.replaceAll("_", " ")) : "Not Provided");

export const formatTransferType = (transfer: TransferUnion): string => {
  if (transfer?.__typename === "PayrollTransfer") {
    return "Payroll (Non-Originated ACH)";
  }
  if (transfer?.__typename === "CreditFunds") {
    return "Credit (Non-Originated ACH)";
  }

  if (transfer?.__typename === "DebitFunds") {
    return "Debit (Non-Originated ACH)";
  }
  if (transfer?.__typename === "SecureDeposit") {
    return "Secured Deposit (Non-Originated ACH)";
  }
  if (transfer?.__typename === "SecureDepositACHTransfer") {
    return "Secured Deposit (Originated ACH)";
  }
  if (
    transfer?.__typename === "IntegratorInitiatedFundsWithdrawalACHTransfer"
  ) {
    return "Withdrawal (Originated ACH)";
  }

  if (transfer?.__typename === "IntegratorInitiatedFundsDepositACHTransfer") {
    return "Deposit (Originated ACH)";
  }
  if (transfer?.__typename === "SecureCardBalanceRepaymentACHTransfer") {
    return "Repayment (Originated ACH)";
  }
  if (transfer?.__typename === "ExternallyInitiatedWireTransfer") {
    return "Wire Transfer (Non-Originated ACH)";
  }
  if (transfer?.__typename === "InterFinancialAccountTransfer") {
    return "Inter Financial Account";
  }
  return "";
};

export const formatAchOriginationType = (status: Maybe<string>): string => {
  switch (status) {
    case AchOriginationType.NON_ORIGINATED:
      return "non-originated";
    case AchOriginationType.ORIGINATED:
      return "originated";
    default:
      return "";
  }
};

export const formatExternallyInitiatedAchStatusReasonCode = (
  statusReasonCode: ExternallyInitiatedAchStatusReasonCode,
): string =>
  statusReasonCode ? title(statusReasonCode.replaceAll("_", " ")) : "";

export const formatIntegratorInitiatedAchStatusReasonCode = (
  statusReasonCode: IntegratorInitiatedAchStatusReasonCode,
): string =>
  statusReasonCode ? title(statusReasonCode.replaceAll("_", " ")) : "";

export const formatWireStatusReasonCode = (
  statusReasonCode: WireStatusReasonCode,
): string =>
  statusReasonCode ? title(statusReasonCode.replaceAll("_", " ")) : "";

export const formatInterFinancialAccountTransferStatusReasonCode = (
  statusReasonCode: InterFinancialAccountTransferStatusReasonCode,
): string =>
  statusReasonCode ? title(statusReasonCode.replaceAll("_", " ")) : "";

export const formatCollaborativeAuthorizationResponseCode = (
  collaborativeAuthorizationResponseCode: Maybe<CollaborativeAuthorizationResponseCode>,
): string => {
  if (!collaborativeAuthorizationResponseCode) {
    return "";
  }
  if (
    collaborativeAuthorizationResponseCode ===
    CollaborativeAuthorizationResponseCode.PARTIAL_AMOUNT_APPROVED
  ) {
    return "Partially Approved";
  }
  return title(collaborativeAuthorizationResponseCode.replaceAll("_", " "));
};

export const formateTruncateAccountHolderName = (
  accountHolderName: Maybe<string | null>,
): string => {
  if (accountHolderName && accountHolderName.length > 30) {
    return accountHolderName.substring(0, 30) + "...";
  }
  if (accountHolderName) {
    return accountHolderName;
  }
  return "";
};

export const formatIntegratorInitiatedTransferType = (
  transferType?: IntegratorInitiatedTransferType,
): string => {
  switch (transferType) {
    case IntegratorInitiatedTransferType.SECURE_DEPOSIT:
      return "Secure Deposit";
    case IntegratorInitiatedTransferType.REPAYMENT:
      return "Repayment";
    case IntegratorInitiatedTransferType.FUNDS_WITHDRAWAL:
      return "Withdrawal";
    case IntegratorInitiatedTransferType.FUNDS_DEPOSIT:
      return "Deposit";

    default:
      return "";
  }
};

export const formatExternallyInitiatedTransferType = (
  transferType?: ExternallyInitiatedTransferType,
): string => {
  switch (transferType) {
    case ExternallyInitiatedTransferType.CREDIT_FUNDS:
      return "Credit";
    case ExternallyInitiatedTransferType.DEBIT_FUNDS:
      return "Debit";
    case ExternallyInitiatedTransferType.PAYROLL:
      return "Payroll";

    default:
      return "";
  }
};

export const formatTransferRuleTypes = (string: Maybe<string>): string => {
  switch (string) {
    case "ACHAmountLimitTransferRule":
      return "Amount";
    case "ACHCountLimitTransferRule":
      return "Count";
    case "ACHStatusTransferRule":
      return "Status";
    case "ACHReturnCodeTransferRule":
      return "Return Code";
    case "ACHMaximumDaysAccountOpenTransferRule":
      return "Maximum Days Account Open";
    case "ACHMinimumDaysAccountOpenTransferRule":
      return "Minimum Days Account Open";
    case "ACHNameMatchTransferRule":
      return "Name Match";
    case "ACHTransactionTypeTransferRule":
      return "Transaction Type";
    default:
      return "";
  }
};

export const formatPhoneInput = (input: PhoneInput): PhoneInput => ({
  countryCode: input.countryCode,
  label: input.label,
  number: input.number.replaceAll("-", ""),
});

export const formatSOSTags = (
  tags: Maybe<AccountHolderVerificationResultCode>[],
): { result: string; icon: string } => {
  const matchString = ((
    tags: Maybe<AccountHolderVerificationResultCode>[],
  ): string => {
    if (
      tags.includes(AccountHolderVerificationResultCode.SOS_MISMATCH) &&
      !tags.includes(AccountHolderVerificationResultCode.SOS_MATCH)
    ) {
      return "No states matched.";
    }

    if (
      !tags.includes(AccountHolderVerificationResultCode.SOS_MISMATCH) &&
      tags.includes(AccountHolderVerificationResultCode.SOS_MATCH)
    ) {
      return "All states matched.";
    }

    if (
      tags.includes(AccountHolderVerificationResultCode.SOS_MISMATCH) &&
      tags.includes(AccountHolderVerificationResultCode.SOS_MATCH)
    ) {
      return "Some states matched, while some did not.";
    }
    return "";
  })(tags);

  const activeString = ((
    tags: Maybe<AccountHolderVerificationResultCode>[],
  ): string => {
    if (
      tags.includes(AccountHolderVerificationResultCode.SOS_INACTIVE) &&
      !tags.includes(AccountHolderVerificationResultCode.SOS_ACTIVE)
    ) {
      return "All statuses were inactive.";
    }

    if (
      !tags.includes(AccountHolderVerificationResultCode.SOS_INACTIVE) &&
      tags.includes(AccountHolderVerificationResultCode.SOS_ACTIVE)
    ) {
      return "All statuses were active.";
    }

    if (
      tags.includes(AccountHolderVerificationResultCode.SOS_INACTIVE) &&
      tags.includes(AccountHolderVerificationResultCode.SOS_ACTIVE)
    ) {
      return "Statuses were both active and inactive.";
    }
    return "";
  })(tags);

  const unknownString = ((
    tags: Maybe<AccountHolderVerificationResultCode>[],
  ): string => {
    if (tags.includes(AccountHolderVerificationResultCode.SOS_UNKNOWN)) {
      return "At least one record was unknown.";
    }

    return "";
  })(tags);

  return {
    result: `${matchString} ${activeString} ${unknownString}`,
    icon:
      matchString === "All states matched." &&
      activeString === "All statuses were active." &&
      unknownString === ""
        ? "/img/approved-icon.svg"
        : "/img/decline-icon.svg",
  };
};

export const formatDocumentUploadSessionStatusCode = (
  status: Maybe<DocumentUploadSessionStatusCode>,
): string => (status ? title(status.replaceAll("_", " ")) : "");

export const formatAuthorizedUserApplicationStatusCode = (
  status?: AuthorizedUserApplicationStatusCode,
): string => {
  switch (status) {
    case AuthorizedUserApplicationStatusCode.APPROVED:
      return "Approved";
    case AuthorizedUserApplicationStatusCode.IN_REVIEW:
      return "In Review";
    case AuthorizedUserApplicationStatusCode.PENDING:
      return "Pending";
    case AuthorizedUserApplicationStatusCode.DENIED:
      return "Denied";
    case AuthorizedUserApplicationStatusCode.CLOSED:
      return "Closed";
    default:
      return "";
  }
};

export const formatUpdateApplicationDocumentStatusCode = (
  status?: ApplicationDocumentUploadStatusInput,
): string => {
  switch (status) {
    case ApplicationDocumentUploadStatusInput.APPROVED:
      return "Approved";
    case ApplicationDocumentUploadStatusInput.IN_REVIEW:
      return "In Review";
    case ApplicationDocumentUploadStatusInput.DENIED:
      return "Denied";
    default:
      return "";
  }
};

export const formatVisaAmountSignage = (
  signage?: VisaAmountSignage,
): string => {
  switch (signage) {
    case VisaAmountSignage.CREDIT:
      return "Credit";
    case VisaAmountSignage.DEBIT:
      return "Debit";
    case VisaAmountSignage.NULL:
      return "";
    default:
      return "";
  }
};

export const formatVisaDiscountTreatment = (
  treatment: Maybe<VisaDiscountTreatment>,
): string => (treatment ? title(treatment.replaceAll("_", " ")) : "");

export const formatVisaTaxTreatment = (
  treatment?: VisaTaxTreatment,
): string => {
  switch (treatment) {
    case VisaTaxTreatment.NLL:
      return "Net prices with taxes calculated at the line item level";
    case VisaTaxTreatment.NIL:
      return "Net prices with taxes calculated at the invoice level";
    case VisaTaxTreatment.GLL:
      return "Gross prices with taxes calculated at the line item level";
    case VisaTaxTreatment.GIL:
      return "Gross prices with taxes calculated at the invoice level";
    case VisaTaxTreatment.NON:
      return "No tax applies on the invoice";
    default:
      return "";
  }
};

export const formatVisaItemCommodityCode = (
  commodityCode: Maybe<VisaItemCommodityCode>,
): string => (commodityCode ? title(commodityCode.replaceAll("_", " ")) : "");

export const formatVisaLineItemDetailIndicator = (
  detailIndicator: Maybe<VisaLineItemDetailIndicator>,
): string => {
  switch (detailIndicator) {
    case VisaLineItemDetailIndicator.CREDIT:
      return "Credit";
    case VisaLineItemDetailIndicator.PAYMENT:
      return "Payment";
    case VisaLineItemDetailIndicator.NORMAL:
      return "";
    default:
      return "";
  }
};

export const formatChargingTime = (time: string): string => {
  const HH = time.slice(0, 2);
  const MM = time.slice(2, 4);
  const SS = time.slice(4, 6);

  return `${HH}:${MM}:${SS} (HHMMSS)`;
};

export const formatValueWithMinorUnit = (
  value: number,
  minorUnit: Maybe<number>,
): number => {
  if (!minorUnit) {
    return value;
  }
  return value / Math.pow(10, minorUnit);
};

export const formatFeeScheduleStatus = (
  status: Maybe<FeeScheduleStatus>,
): string => (status ? title(status.replaceAll("_", " ")) : "");

export const formatFeeActivityType = (
  activityType:
    | FeeTransferEventFeeActivityTypeInput
    | FeeTransferEventFeeActivityType,
): string => title(activityType.replaceAll("_", " "), { special: ["ATM"] });

export const formatFeeChargeActivityTypename = (
  chargeActivityTypename: Maybe<string>,
): string => {
  switch (chargeActivityTypename) {
    case "PhysicalPaymentCardOrder":
      return "Physical Payment Card Order";
    case "Payment Card":
      return "Payment Card";
    case "FinancialAccount":
      return "Financial Account";
    case "SecureDepositACHTransfer":
      return "Secured Deposit (Originated ACH)";
    case "SecureCardBalanceRepaymentACHTransfer":
      return "Repayment (Originated ACH)";
    case "IntegratorInitiatedFundsWithdrawalACHTransfer":
      return "Withdrawal (Originated ACH)";
    case "IntegratorInitiatedFundsDepositACHTransfer":
      return "Deposit (Originated ACH)";
    case "AuthorizationAndClearEvent":
      return "Authorization And Clear";
    case "ClearingEvent":
      return "Clearing";
    case "BalanceInquiryEvent":
      return "Balance Inquiry";
    default:
      return "";
  }
};

export const formatNameMatch = (
  nameMatchString: Maybe<String>,
  revertDisplayString?: String,
): string => {
  if (revertDisplayString === "revertDisplayString") {
    switch (nameMatchString) {
      case "Match":
        return "EXACT_MATCH";
      case "Fuzzy Match":
        return "JARO_WINKLER_FUZZY_MATCH";
      case "Mismatch":
        return "MISMATCH";
      default:
        return "";
    }
  }
  switch (nameMatchString) {
    case "EXACT_MATCH":
      return "Match";
    case "JARO_WINKLER_FUZZY_MATCH":
      return "Fuzzy Match";
    case "MISMATCH":
      return "Mismatch";
    default:
      return "";
  }
};

export const formatAccountClosureBlocker = (
  blocker: Maybe<AccountClosureBlocker>,
): string => (blocker ? title(blocker.replaceAll("_", " ")) : "");

export const formatDenyCreditProductApplicationUnderwritingAdverseActions = (
  reason: Maybe<AdverseActionCode>,
): string => (reason ? title(reason.replaceAll("_", " ")) : "");

export const formatFinancialAccountClosureRequesterType = (
  requesterType: Maybe<FinancialAccountClosureRequesterType>,
): string => (requesterType ? title(requesterType.replaceAll("_", " ")) : "");

export const formatFinancialAccountCloseReason = (
  closeReason: Maybe<FinancialAccountCloseReason>,
): string => (closeReason ? title(closeReason.replaceAll("_", " ")) : "");

export const formatSetFinancialAccountAttribute = (
  attribute: Maybe<FinancialAccountAttributeInput>,
): string => (attribute ? title(attribute.replaceAll("_", " ")) : "");

export const formatFinancialEventType = (
  financialEventTypename: Maybe<string>,
): string => {
  switch (financialEventTypename) {
    case "AuthorizationAndClearEvent":
      return "Authorization and Clear";
    case "AuthorizationEvent":
      return "Authorization";
    case "ClearingEvent":
      return "Clearing";
    case "CreditFundsACHTransferEvent":
      return "Credit • Non-Originated ACH";
    case "DebitFundsACHTransferEvent":
      return "Debit • Non-Originated ACH";
    case "ExternallyInitiatedAchReleaseHoldTransfer":
      return "Externally Initiated ACH Release Hold";
    case "FeeTransferEvent":
      return "Fee";
    case "FinancialAccountCreditLimitUpdateFromProductFunding":
      return "Financial Account Credit Limit Update";
    case "IntegratorInitiatedFundsDepositACHTransferEvent":
      return "Deposit • Originated ACH";
    case "IntegratorInitiatedFundsWithdrawalACHTransferEvent":
      return "Withdrawal • Originated ACH";
    case "InterFinancialAccountTransfer":
      return "Inter Financial Account";
    case "IssuerPreliminaryAuthorizationEvent":
      return "Issuer Preliminary Authorization";
    case "PayrollACHTransferEvent":
      return "Payroll • Non-Originated ACH";
    case "PayrollAdvanceRepaymentTransfer":
      return "Payroll Advance Repayment";
    case "PayrollAdvanceWriteoffTransfer":
      return "Payroll Advance Write-off";
    case "ReversalEvent":
      return "Reversal";
    case "RewardPointsTransfer":
      return "Reward Points";
    case "SecureCardBalanceRepaymentACHTransferEvent":
      return "Repayment • Originated ACH";
    case "SecureDepositACHTransferEvent":
      return "Secured Deposit • Originated ACH";
    case "SecureDepositTransferEvent":
      return "Secured Deposit • Non-Originated ACH";
    case "Transfer":
      return "Transfer";
    case "FinancialAccountPseudoBalanceUpdate":
      return "Financial Account Pseudo Balance Update";
    case "ManualAdjustmentEvent":
      return "Manual Adjustment";
    default:
      return "";
  }
};

export const formatFinancialAccountAttribute = (
  attribute: Maybe<FinancialAccountAttribute>,
): string => (attribute ? title(attribute.replaceAll("_", " ")) : "");

export const formatReportType = (reportType: Maybe<ReportType>): string => {
  if (!reportType) {
    return "";
  }

  switch (reportType) {
    case ReportType.EXPERIAN_CREDIT_REPORT:
      return "Experian Credit Report";
    case ReportType.CARD_INTERCHANGE_ACTIVITY:
      return "Card Interchange Activity Report";
    case ReportType.LEDGER_ENTRY:
      return "Ledger Entry Report";
    case ReportType.CARD_TRANSACTION_ACTIVITY:
      return "Card Transaction Activity Report";
    case ReportType.FLEET_ENHANCED_DATA_SUMMARY:
      return "Fleet Enhanced Data Summary";
    default:
      return "Unknown";
  }
};

export const getReportTypeIcon = (reportType?: ReportType) => {
  switch (reportType) {
    case ReportType.EXPERIAN_CREDIT_REPORT:
      return "/img/experian-icon.svg";
    case ReportType.LEDGER_ENTRY:
      return "/img/ledger-icon.svg";
    case ReportType.CARD_INTERCHANGE_ACTIVITY:
      return "/img/interchange-activity-icon.svg";
    case ReportType.CARD_TRANSACTION_ACTIVITY:
      return "/img/transaction-icon.svg";
    case ReportType.FLEET_ENHANCED_DATA_SUMMARY:
      return "/img/fleet-card-icon.svg";
    default:
      return "/img/reports-icon.svg";
  }
};

export const formatReportName = (
  reportType?: ReportType,
  updatedAt?: string,
) => {
  const reportDate = updatedAt ? new Date(updatedAt) : new Date();
  const formattedDate = format(reportDate, "MM-dd-yyyy");

  const type = formatReportType(reportType);

  return `${type} - ${formattedDate}.csv`;
};

export const formatTransferPurpose = (
  purpose: Maybe<TransferPurpose>,
): string => (purpose ? title(purpose.replaceAll("_", " ")) : "");

export const formatTransferActivityType = (
  activityType: Maybe<InterFinancialAccountTransferActivityType>,
): string => (activityType ? title(activityType.replaceAll("_", " ")) : "");

export const formatDisputeCategory = (
  category: Maybe<PaymentCardDisputeCategoryType>,
): string => (category ? title(category.replaceAll("_", " ")) : "");

export const formatDisputeStatus = (
  status: Maybe<PaymentCardDisputeStatus>,
): string => (status ? title(status.replaceAll("_", " ")) : "");

export const formatChargebackStatus = (
  status: Maybe<PaymentCardChargebackStatus>,
): string => (status ? title(status.replaceAll("_", " ")) : "");

export const formatCardArtworkUploadTitle = (
  documentType: Maybe<DocumentType>,
) => {
  switch (documentType) {
    case DocumentType.DIGITAL_FRONT_OF_CARD:
      return "Front of Card";
    case DocumentType.DIGITAL_BACKGROUND:
      return "Background";
    case DocumentType.DIGITAL_LOGO:
      return "Logo";
    case DocumentType.DIGITAL_ICON:
      return "Icon";
    case DocumentType.PHYSICAL_FRONT_OF_CARD:
      return "Front of Card";
    case DocumentType.PHYSICAL_BACK_OF_CARD:
      return "Back of Card";
    case DocumentType.VIRTUAL_FRONT_OF_CARD:
      return "Front of Card";

    default:
      return "Upload file";
  }
};

export const formatCardArtworkUploadDescription = (
  documentType: Maybe<DocumentType>,
) => {
  switch (documentType) {
    case DocumentType.DIGITAL_FRONT_OF_CARD:
      return "Upload front of card";
    case DocumentType.DIGITAL_BACKGROUND:
      return "Upload back of card";
    case DocumentType.DIGITAL_LOGO:
      return "Upload logo";
    case DocumentType.DIGITAL_ICON:
      return "Upload icon";
    case DocumentType.PHYSICAL_FRONT_OF_CARD:
      return "Upload front of card";
    case DocumentType.PHYSICAL_BACK_OF_CARD:
      return "Upload back of card";
    case DocumentType.VIRTUAL_FRONT_OF_CARD:
      return "Upload front of card";

    default:
      return "Upload file";
  }
};

export const formatCardArtworkUploadFiletypes = (
  documentType: Maybe<DocumentType>,
) => {
  switch (documentType) {
    case DocumentType.DIGITAL_FRONT_OF_CARD:
      return "PNG or JPG";
    case DocumentType.DIGITAL_BACKGROUND:
      return "PNG or JPG";
    case DocumentType.DIGITAL_LOGO:
      return "PNG or SVG";
    case DocumentType.PHYSICAL_FRONT_OF_CARD:
      return "AI";
    case DocumentType.PHYSICAL_BACK_OF_CARD:
      return "AI";
    case DocumentType.VIRTUAL_FRONT_OF_CARD:
      return "PNG or JPG";

    default:
      return "PNG";
  }
};

export const formatCardArtworkUploadMimetypes = (
  documentType: Maybe<DocumentType>,
) => {
  switch (documentType) {
    case DocumentType.DIGITAL_FRONT_OF_CARD:
      return {
        "image/png": [],
        "image/jpeg": [],
      };
    case DocumentType.DIGITAL_BACKGROUND:
      return {
        "image/png": [],
        "image/jpeg": [],
      };
    case DocumentType.DIGITAL_LOGO:
      return {
        "image/png": [],
        "image/jpeg": [],
        "image/svg+xml": [],
      };
    case DocumentType.PHYSICAL_FRONT_OF_CARD:
      return {
        "application/postscript": [".ai"],
      } as { [key: string]: string[] };
    case DocumentType.PHYSICAL_BACK_OF_CARD:
      return {
        "application/postscript": [".ai"],
      } as { [key: string]: string[] };
    case DocumentType.VIRTUAL_FRONT_OF_CARD:
      return {
        "image/png": [],
        "image/jpeg": [],
      };

    default:
      return {
        "image/png": [],
        "image/jpeg": [],
      };
  }
};

export const parseCardArtDocumentUploadSessionInfo = (
  documents: CardArtDocument[],
) => {
  return documents?.reduce(
    (acc, document) => {
      if (document.documentType === CardArtDocumentType.VIRTUAL_FRONT_OF_CARD) {
        acc.virtualFrontOfCard = document;
        return acc;
      }
      if (
        document.documentType === CardArtDocumentType.PHYSICAL_FRONT_OF_CARD
      ) {
        acc.physicalFrontOfCard = document;
        return acc;
      }
      if (document.documentType === CardArtDocumentType.PHYSICAL_BACK_OF_CARD) {
        acc.physicalBackOfCard = document;
        return acc;
      }
      if (document.documentType === CardArtDocumentType.DIGITAL_FRONT_OF_CARD) {
        acc.digitalFrontOfCard = document;
        return acc;
      }
      if (document.documentType === CardArtDocumentType.DIGITAL_BACKGROUND) {
        acc.digitalBackground = document;
        return acc;
      }
      if (document.documentType === CardArtDocumentType.DIGITAL_LOGO) {
        acc.digitalLogo = document;
        return acc;
      }
      if (document.documentType === CardArtDocumentType.DIGITAL_ICON) {
        acc.digitalIcon = document;
        return acc;
      }

      return acc;
    },
    {} as {
      virtualFrontOfCard: CardArtDocument;
      physicalFrontOfCard: CardArtDocument;
      physicalBackOfCard: CardArtDocument;
      digitalFrontOfCard: CardArtDocument;
      digitalBackground: CardArtDocument;
      digitalLogo: CardArtDocument;
      digitalIcon: CardArtDocument;
    },
  );
};

export const formatCreditRepaymentMethod = (
  method?: Maybe<CreditRepaymentMethod>,
): string => {
  switch (method) {
    case CreditRepaymentMethod.ACH:
      return "ACH";
    case CreditRepaymentMethod.MANUAL_ADJUSTMENT:
      return "Manual Adjustment";
    default:
      return "";
  }
};

export const formatBillingCyclePeriod = (
  period: Maybe<CreditStatementCyclePeriod>,
): string => (period ? title(period.replaceAll("_", " ")) : "");

export const formatGracePeriodType = (
  periodType: Maybe<CreditStatementGracePeriodType>,
): string => (periodType ? title(periodType.replaceAll("_", " ")) : "");

export const formatMastercardFleetFuelBrand = (
  fuelBrand: Maybe<MastercardFleetFuelBrand>,
): string => (fuelBrand ? title(fuelBrand.replaceAll("_", " ")) : "");

export const formatMastercardFleetFuelServiceType = (
  serviceType: Maybe<MastercardFleetFuelServiceType>,
): string => (serviceType ? title(serviceType.replaceAll("_", " ")) : "");

export const formatMastercardFleetProductCode = (
  productCode: Maybe<MastercardFleetProductCode>,
): string => (productCode ? title(productCode.replaceAll("_", " ")) : "");

export const formatMastercardFleetFuelUnitOfMeasure = (
  unitOfMeasure: Maybe<MastercardFleetUnitOfMeasure>,
): string => (unitOfMeasure ? title(unitOfMeasure.replaceAll("_", " ")) : "");

export const formatMastercardFleetProductType = (
  productType: Maybe<MastercardFleetProductType>,
): string => (productType ? title(productType.replaceAll("_", " ")) : "");

export const formatMastercardFleetCreditOrDebitIndicator = (
  indicator: Maybe<MastercardFleetCreditOrDebitIndicator>,
): string => (indicator ? title(indicator.replaceAll("_", " ")) : "");

export const formatPurchaseTime = (time: string): string => {
  const HH = time.slice(0, 2);
  const MM = time.slice(2, 4);

  return `${HH}:${MM} (HHMM)`;
};

export const formatMastercardFuelUnitOfMeasure = (
  unitOfMeasure: Maybe<MastercardFleetUnitOfMeasure>,
): string => {
  if (
    !unitOfMeasure ||
    unitOfMeasure === MastercardFleetUnitOfMeasure.NOT_APPLICABLE
  ) {
    return "";
  }

  return title(unitOfMeasure.replaceAll("_", " "));
};

export const formatMastercardFleetFuelLocationDetails = (
  detail: string,
): string => {
  switch (detail) {
    case "alternativeFuel":
      return "Alternative Fuel";
    case "aviationLocation":
      return "Aviation Location";
    case "convenienceStore":
      return "Convenience Store";
    case "dieselSites":
      return "Diesel Sites";
    case "eighteenWheelerAccess":
      return "Eighteen Wheeler Access";
    case "hotel":
      return "Hotel";
    case "interstateAccess":
      return "Interstate Access";
    case "marinaLocation":
      return "Marina Location";
    case "openTwentyFourHours":
      return "Open 24 Hours";
    case "payAtPump":
      return "Pay At Pump";
    case "repairBays":
      return "Repair Bays";
    case "restaurant":
      return "Restaurant";
    case "showers":
      return "Showers";
    case "vehicleWash":
      return "Vehicle Wash";

    default:
      return "";
  }
};

export const formatDeliveryAttemptStatus = (
  deliveryAttemptStatus: Maybe<DeliveryAttemptStatus>,
): string => {
  switch (deliveryAttemptStatus) {
    case DeliveryAttemptStatus.SUCCESS:
      return "Success";
    case DeliveryAttemptStatus.FAILURE:
      return "Failed";
    case DeliveryAttemptStatus.SKIPPED:
      return "Skipped";
    default:
      return "";
  }
};

export const formatCreditPlanStatus = (
  status: Maybe<CreditPlanStatus>,
): string => (status ? title(status.replaceAll("_", " ")) : "");

export const formatCreditPlanInterestRateType = (
  schedule: Maybe<InterestRateType>,
): string => (schedule ? title(schedule.replaceAll("_", " ")) : "");

export const formatCreditPlanType = (type: Maybe<CreditPlanType>): string =>
  type ? title(type.replaceAll("_", " ")) : "";

export const formatCreditPlanBalanceType = (
  type: Maybe<CreditBalanceType>,
): string => (type ? title(type.replaceAll("_", " ")) : "");

export const formatPayOffType = (type: Maybe<PayOffType>): string =>
  type ? title(type.replaceAll("_", " ")) : "";

export const formatBalanceType = (type: Maybe<BalanceType>): string =>
  type ? title(type.replaceAll("_", " ")) : "";

export const formatPayOffDays = (days: number) => {
  if (!days) return;
  if (days < 15) {
    return "0 Months";
  } else if (days < 45) {
    return "1 Month";
  } else if (days < 730) {
    if (days % 30 < 15) {
      //round down
      return `${Math.floor(days / 30)} Months`;
    } else {
      //round up
      return `${Math.ceil(days / 30)} Months`;
    }
  } else {
    if (days % 365 < 182) {
      //round down
      return `${Math.floor(days / 365)} Years`;
    } else {
      //round up
      return `${Math.ceil(days / 365)} Years`;
    }
  }
};

export const formatDigitalWalletProvider = (
  digitalWalletProvider: Maybe<DigitalWalletProvider>,
): string =>
  digitalWalletProvider
    ? title(digitalWalletProvider.replaceAll("_", " "))
    : "";

export const formatPhysicalCardArtOrientation = (
  physicalCardArtOrientation: Maybe<PhysicalCardArtOrientation>,
): string =>
  physicalCardArtOrientation
    ? title(physicalCardArtOrientation.replaceAll("_", " "))
    : "";

export const formatVirtualCardPersonalizationType = (
  virtualCardPersonalizationType: Maybe<VirtualCardPersonalizationType>,
) =>
  virtualCardPersonalizationType
    ? title(virtualCardPersonalizationType.replaceAll("_", " "))
    : "";

export const formatPhysicalCardVendorName = (
  physicalCardVendorName: Maybe<PhysicalCardVendorName>,
) =>
  physicalCardVendorName
    ? title(physicalCardVendorName.replaceAll("_", " "))
    : "";

export const formatPhysicalCardType = (
  physicalCardType: Maybe<PhysicalCardType>,
) => (physicalCardType ? title(physicalCardType.replaceAll("_", " ")) : "");

export const formatPhysicalCardMaterial = (
  physicalCardMaterial: Maybe<PhysicalCardMaterial>,
) =>
  physicalCardMaterial ? title(physicalCardMaterial.replaceAll("_", " ")) : "";

export const formatPhysicalCardPersonalizationFormat = (
  physicalCardPersonalizationFormat: Maybe<PhysicalCardPersonalizationFormat>,
) =>
  physicalCardPersonalizationFormat
    ? title(physicalCardPersonalizationFormat.replaceAll("_", " "))
    : "";

export const formatPhysicalCardPersonalizationLineType = (
  physicalCardPersonalizationLineType: Maybe<PhysicalCardPersonalizationLineType>,
) =>
  physicalCardPersonalizationLineType
    ? title(physicalCardPersonalizationLineType.replaceAll("_", " "))
    : "";

export const distanceUnitToString = (unit: DistanceUnit): string => {
  switch (unit) {
    case DistanceUnit.MILE:
      return "Mile";
    case DistanceUnit.KILOMETER:
      return "Kilometer";
    default:
      return "";
  }
};

export const formatManualAdjustmentActivityType = (
  activityType: Maybe<ManualAdjustmentActivityType>,
): string => (activityType ? title(activityType.replaceAll("_", " ")) : "");
