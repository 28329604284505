import { TypePolicies } from "@apollo/client";
import { relayStylePagination } from "@apollo/client/utilities";

export const transferRuleTypePolicies: TypePolicies = {
  ACHAmountLimitTransferRule: {
    fields: {
      revisions: relayStylePagination(),
    },
    keyFields: ["id", "version"],
  },
  ACHCountLimitTransferRule: {
    fields: {
      revisions: relayStylePagination(),
    },
    keyFields: ["id", "version"],
  },
  ACHMaximumDaysAccountOpenTransferRule: {
    fields: {
      revisions: relayStylePagination(),
    },
    keyFields: ["id", "version"],
  },
  ACHMinimumDaysAccountOpenTransferRule: {
    fields: {
      revisions: relayStylePagination(),
    },
    keyFields: ["id", "version"],
  },
  ACHNameMatchTransferRule: {
    fields: {
      revisions: relayStylePagination(),
    },
    keyFields: ["id", "version"],
  },
  ACHStatusTransferRule: {
    fields: {
      revisions: relayStylePagination(),
    },
    keyFields: ["id", "version"],
  },
  ACHReturnCodeTransferRule: {
    fields: {
      revisions: relayStylePagination(),
    },
    keyFields: ["id", "version"],
  },
  ACHTransactionTypeTransferRule: {
    fields: {
      revisions: relayStylePagination(),
    },
    keyFields: ["id", "version"],
  },
};
