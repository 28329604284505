import { sha256 } from "js-sha256";

function getUrl(project: string): string {
  const sanitizedBranchName =
    process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF?.replace("/", "-")
      .replace("@", "")
      .replace(/[./_]+/gu, "");

  const initialSubdomain = `${project}-git-${sanitizedBranchName}`;

  if (initialSubdomain.length < 63) {
    return `https://${initialSubdomain}.bay1.site`;
  }

  // Truncation as per https://vercel.com/docs/deployments/generated-urls#truncation
  const hashSuffix = sha256(
    `git-${process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF}${project}`,
  ).slice(0, 6);

  return `https://${initialSubdomain.slice(0, 56)}-${hashSuffix}.bay1.site`;
}

export const DASHBOARD_URL =
  process.env.NEXT_PUBLIC_DASHBOARD_URL ?? getUrl("dashboard");

export const MARKETING_URL =
  process.env.NEXT_PUBLIC_MARKETING_URL ?? getUrl("marketing");

export const OPS_URL = process.env.NEXT_PUBLIC_OPS_URL ?? getUrl("ops");

export const isProductionDeployment =
  process.env.NEXT_PUBLIC_DASHBOARD_URL === "https://dashboard.highnote.com";

export const getInitials = (name: string | undefined): string => {
  if (name === "My Company" || name === undefined) {
    return "";
  }

  return name
    .split(" ")
    .map((word) => word.charAt(0))
    .join("");
};
