import { createGlobalStyle } from "styled-components";

import ArrowButton from "./components/ArrowButton";
import Avatar from "./components/Avatar";
import FooterComponent from "./components/Footer";
import HomeModalComponent from "./components/HomeModal";
import { LiveOnly } from "./components/LiveOnly";
import SidebarNav, { SidebarLink } from "./components/SidebarNav";
import { Toggle } from "./components/Toggle";

export { ConditionalWrapper } from "./components/ConditionalWrapper";
export { ErrorBox } from "./components/ErrorBox";
export { LoadingSpinner, PageLoader } from "./components/Loader";
export {
  preventAccidentalSlideOverDismissal,
  SlideOver,
  type SlideOverHeaderProps,
} from "./components/SlideOver";
export { TestOnly } from "./components/TestOnly";

export const Footer = FooterComponent;
export const HomeModal = HomeModalComponent;

const GlobalStyle = createGlobalStyle`
    html,
    body {
        padding: 0;
        margin: 0;
        @apply font-sans;
        height: 100%;
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    * {
        box-sizing: border-box;
    }

    .link {
      @apply cursor-pointer border-b border-brand-accent text-brand-accent hover:text-white hover:bg-green duration-300 ease-in-out;
    }
`;

export default GlobalStyle;
export { Avatar };
export { ArrowButton };
export { SidebarNav };
export { SidebarLink };
export { Toggle };
export { LiveOnly };
export { CheckboxField } from "./components/CheckboxField";
export { DetailViewLoader } from "./components/DetailViewLoader";
export type { EmptyDataStateProps } from "./components/EmptyDataState";
export { EmptyDataState } from "./components/EmptyDataState";
export { ErrorMessage } from "./components/ErrorMessage";
export { Field } from "./components/Field";
export { FieldLabel } from "./components/FieldLabel";
export { Dropdown } from "./components/FilterDropdown";
export { FilterPill } from "./components/FilterPill";
export { FinancialAccountStatusPill } from "./components/FinancialAccountStatusPill";
export { FormGroup } from "./components/FormGroup";
export { LoaderTable } from "./components/LoaderTable";
export { LoadMore } from "./components/LoadMore";
export { ConfirmModal } from "./components/modal/ConfirmModal";
export { ConfirmModalContextProvider } from "./components/modal/ConfirmModalContextProvider";
export { useConfirm } from "./components/modal/useConfirm";
export { ReportDirty } from "./components/ReportDirty";
export { SelectField } from "./components/SelectField";
export { Table } from "./components/tables";
export { TransactionEventResponseCodePill } from "./components/TransactionEventResponseCodePill";
export { LoadMoreButton } from "./LoadMoreButton";
