import { TypePolicies } from "@apollo/client";
import { relayStylePagination } from "@apollo/client/utilities";

export const riskRuleTypePolicies: TypePolicies = {
  ActivityAfterDaysDormantRiskRule: {
    fields: {
      revisions: relayStylePagination(),
    },
    keyFields: ["id", "version"],
  },
  AmountLimitRiskRule: {
    fields: {
      revisions: relayStylePagination(),
    },
    keyFields: ["id", "version"],
  },
  CardTransactionEventTypeConditionRiskRule: {
    fields: {
      revisions: relayStylePagination(),
    },
    keyFields: ["id", "version"],
  },
  CardTransactionProcessingTypeConditionRiskRule: {
    fields: {
      revisions: relayStylePagination(),
    },
    keyFields: ["id", "version"],
  },
  ConditionalRuleSetRiskRule: {
    fields: {
      revisions: relayStylePagination(),
    },
    keyFields: ["id", "version"],
  },
  CountLimitRiskRule: {
    fields: {
      revisions: relayStylePagination(),
    },
    keyFields: ["id", "version"],
  },
  CVVRiskRule: {
    fields: {
      revisions: relayStylePagination(),
    },
    keyFields: ["id", "version"],
  },
  DepositAmountLimitRiskRule: {
    fields: {
      revisions: relayStylePagination(),
    },
    keyFields: ["id", "version"],
  },
  DepositCountLimitRiskRule: {
    fields: {
      revisions: relayStylePagination(),
    },
    keyFields: ["id", "version"],
  },
  DepositProcessingNetworkRiskRule: {
    fields: {
      revisions: relayStylePagination(),
    },
    keyFields: ["id", "version"],
  },
  ForcedPostTransactionRiskRule: {
    fields: {
      revisions: relayStylePagination(),
    },
    keyFields: ["id", "version"],
  },
  GeolocationTrackingRiskRule: {
    fields: {
      revisions: relayStylePagination(),
    },
    keyFields: ["id", "version"],
  },
  LargeACHDepositWithinDaysRiskRule: {
    fields: {
      revisions: relayStylePagination(),
    },
    keyFields: ["id", "version"],
  },
  LargeRefundRiskRule: {
    fields: {
      revisions: relayStylePagination(),
    },
    keyFields: ["id", "version"],
  },
  MaximumDaysAccountOpenRiskRule: {
    fields: {
      revisions: relayStylePagination(),
    },
    keyFields: ["id", "version"],
  },
  MerchantCategoryRiskRule: {
    fields: {
      revisions: relayStylePagination(),
    },
    keyFields: ["id", "version"],
  },
  MerchantCountryRiskRule: {
    fields: {
      revisions: relayStylePagination(),
    },
    keyFields: ["id", "version"],
  },
  MerchantIdentifierRiskRule: {
    fields: {
      revisions: relayStylePagination(),
    },
    keyFields: ["id", "version"],
  },
  MinimumDaysAccountOpenRiskRule: {
    fields: {
      revisions: relayStylePagination(),
    },
    keyFields: ["id", "version"],
  },
  PointOfServiceCategoryRiskRule: {
    fields: {
      revisions: relayStylePagination(),
    },
    keyFields: ["id", "version"],
  },
  ResponseCodeRiskRule: {
    fields: {
      revisions: relayStylePagination(),
    },
    keyFields: ["id", "version"],
  },
  StreetAddressRiskRule: {
    fields: {
      revisions: relayStylePagination(),
    },
    keyFields: ["id", "version"],
  },
  UnmatchedRefundRiskRule: {
    fields: {
      revisions: relayStylePagination(),
    },
    keyFields: ["id", "version"],
  },
  UnmatchedReversalRiskRule: {
    fields: {
      revisions: relayStylePagination(),
    },
    keyFields: ["id", "version"],
  },
  UnusualVelocityOfAuthorizationsRiskRule: {
    fields: {
      revisions: relayStylePagination(),
    },
    keyFields: ["id", "version"],
  },
};
